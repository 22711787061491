import { keyframes } from 'styled-components';

export const spin = () => keyframes`
  0 { transform: rotate(0); }
  100% { transform: rotate(1turn); }
`;

export const pulse = (transform?: string) => keyframes`
  0% { opacity: 0; transform: ${transform ? `${transform} ` : ''}scale(0.5); }
  40%, 45% { opacity: 1; transform: ${transform ? `${transform} ` : ''}scale(1); }
  60%, 100% { opacity: 0; transform: ${transform ? `${transform} ` : ''}scale(0.95); }
`;
