import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { sortBy } from 'lodash';

import Button from 'components/button/button';
import Toggle from 'components/toggle/toggle';
import UserInvite from 'components/userInvite/userInvite';
import { UserContext, UserContextType } from 'context/user';
import { ToolContextType, ToolContext } from 'context/tool';
import { Site } from 'context/tool.types';
import useUserSites from 'hooks/useUserSites';
import useModal from 'hooks/useModal';
import { updateSitePublicStatus } from 'utils/api';
import { PAGE_COLORS } from 'utils/routes';

import * as Styled from './userAuthorizations.styles';

interface UserAuthorizationsProps {
  isHovered?: boolean;
  onHoverChange(state: boolean): void;
}

const UserAuthorizations = ({
  isHovered,
  onHoverChange,
}: UserAuthorizationsProps) => {
  const { user }: UserContextType = useContext(UserContext);
  const { getUserSites }: ToolContextType = useContext(ToolContext);
  const [sharedSite, setSharedSite] = useState<Site>(null);
  const { fetchUserSites } = useUserSites();

  const userCreatedSites = useMemo(
    () => getUserSites().filter((site) => site.creator_id === user.id),
    [getUserSites, user.id]
  );

  const onTogglePublic = async (
    siteId: Site['id'],
    toggledState: Site['public']
  ) => {
    await updateSitePublicStatus(siteId, toggledState);
    fetchUserSites();
  };

  const userInviteModalDOM = useMemo(
    () =>
      sharedSite ? (
        <UserInvite
          user={user}
          sharedSite={sharedSite}
          onSetSharedSite={(site) => {
            setSharedSite(site);
          }}
        />
      ) : null,
    [sharedSite]
  );

  const { disposeModal } = useModal(
    'userInvite',
    userInviteModalDOM,
    !!sharedSite,
    null,
    null,
    () => {
      setSharedSite(null);
    }
  );

  useEffect(() => {
    return () => {
      // Dispose of modal
      disposeModal();
    };
  }, [disposeModal]);

  return (
    <Styled.Wrapper
      onMouseMove={() => !isHovered && onHoverChange(true)}
      onMouseLeave={() => onHoverChange(false)}
      isHovered={isHovered}
      color={PAGE_COLORS.userProfile}
    >
      <Styled.Header>Authorisations</Styled.Header>
      <Styled.SiteList>
        <Styled.SiteListLabel>Sites</Styled.SiteListLabel>
        <Styled.SiteListContent>
          {sortBy(userCreatedSites, (site) => site.name.toLowerCase()).map(
            (site) => (
              <Styled.Site key={site.id}>
                <span>{site.name}</span>
                <Styled.SiteDetails>
                  {site.public && (
                    <Styled.SiteDetailsShare>
                      <Button
                        label="Share"
                        color={PAGE_COLORS.userProfile}
                        // Don't count the site creator
                        count={site.users.length - 1}
                        onClick={() => setSharedSite(site)}
                      />
                    </Styled.SiteDetailsShare>
                  )}
                  <Styled.SiteDetailsPublic>
                    <span>{site.public ? 'Public' : 'Private'}</span>
                    <Toggle
                      value={site.public}
                      onChange={() => onTogglePublic(site.id, !site.public)}
                    />
                  </Styled.SiteDetailsPublic>
                </Styled.SiteDetails>
              </Styled.Site>
            )
          )}
        </Styled.SiteListContent>
      </Styled.SiteList>
    </Styled.Wrapper>
  );
};

export default UserAuthorizations;
