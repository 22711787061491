const theme = {
  // "Name that Color" names
  colors: {
    white: '#fff',
    black: '#000',
    ronchi: '#ecc050',
    anakiwa: '#a3e3ff',
    blue: '#1e2ff9',
    mauve: '#c2a3fd',
    algaeGreen: '#87e0be',
    flesh: '#ffd5a8',
    atomicTangerine: '#ff975c',
    yellowGreen: '#b7e37d'
  },
  fonts: {
    face: {
      // eslint-disable-next-line quotes
      primary: "Manrope, 'Helvetica Neue', Helvetica, Arial, sans-serif"
    },
    scale: {
      // Based on Material design typography scale, adjust as needed
      // fontSize: rem, letterSpacing: rem
      heading1: {
        fontSize: { tablet: 96, mobile: 96 },
        lineHeight: { tablet: 1.35, mobile: 1.35 },
        fontWeight: 500,
        letterSpacing: 0,
        textTransform: 'none'
      },
      heading2: {
        fontSize: { tablet: 80, mobile: 80 },
        lineHeight: { tablet: 1.35, mobile: 1.35 },
        fontWeight: 500,
        letterSpacing: 0,
        textTransform: 'none'
      },
      heading3: {
        fontSize: { tablet: 36, mobile: 36 },
        lineHeight: { tablet: 1.35, mobile: 1.35 },
        fontWeight: 600,
        letterSpacing: 0,
        textTransform: 'none'
      },
      body1: {
        fontSize: { tablet: 28, mobile: 28 },
        lineHeight: { tablet: 1.5, mobile: 1.5 },
        fontWeight: 600,
        letterSpacing: 0.84,
        textTransform: 'none'
      },
      body2: {
        fontSize: { tablet: 48, mobile: 48 },
        lineHeight: { tablet: 1.5, mobile: 1.5 },
        fontWeight: 600,
        letterSpacing: 0,
        textTransform: 'none'
      },
      button: {
        fontSize: { tablet: 28, mobile: 28 },
        lineHeight: { tablet: 1.5, mobile: 1.5 },
        fontWeight: 700,
        letterSpacing: 0.84,
        textTransform: 'none'
      },
      input: {
        fontSize: { tablet: 32, mobile: 32 },
        lineHeight: { tablet: 1.5, mobile: 1.5 },
        fontWeight: 600,
        letterSpacing: 0,
        textTransform: 'none'
      },
      small: {
        fontSize: { tablet: 24, mobile: 24 },
        lineHeight: { tablet: 1.5, mobile: 1.5 },
        fontWeight: 700,
        letterSpacing: 1.2,
        textTransform: 'none'
      },
      caption: {
        fontSize: { tablet: 28, mobile: 28 },
        lineHeight: { tablet: 1.5, mobile: 1.5 },
        fontWeight: 600,
        letterSpacing: 5.6,
        textTransform: 'uppercase'
      },
      label: {
        fontSize: { tablet: 24, mobile: 24 },
        lineHeight: { tablet: 1.5, mobile: 1.5 },
        fontWeight: 600,
        letterSpacing: 1.2,
        textTransform: 'uppercase'
      },
    }
  },
  layout: {
    pagePadding: { w: 100, h: 60 }, // rem
    contentPadding: { h: 80 }, // rem
    borderRadius: 40, // rem
    lineOpacity: 0.1,
    inactiveBackgroundOpacity: 0.05,
    header: { h: 10 }, // vh
    headerSection: { h: 190 }, // rem
    footer: { h: 120 }, // rem
    sideBar: { w: 25 }, // %
    stepProgress: { w: 4 } // vw
  }
} as const;

// Aliases
export const { colors, fonts, layout } = theme;

export default theme;
