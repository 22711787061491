export const CODES = [200, 201, 403, 404, 409, 422] as const;

/*
  200: User authorised for site
  404: User not found or Site not found
  422: Bad request, provide both user_id and accept_request
*/

export const AUTH_RESULTS_CODES: Record<string, typeof CODES[number]> = {
  SUCCESS: 200,
  NOT_FOUND: 404,
  BAD_REQUEST: 422
} as const;

export const AUTH_RESULTS_MESSAGES: Record<keyof typeof AUTH_RESULTS_CODES, string> = {
  SUCCESS: 'User authorised',
  NOT_FOUND: 'User couldn\'t be found',
  BAD_REQUEST: 'An error occurred'
} as const;

/*
  201: Email sent to user informing them they have been invited to a site
  403: The user does not have the same council as the site,
       or the user trying to invite another user is not the creator of the site
  409: Invite already sent, cannot send multiple invites
*/

export const INVITE_RESULTS_CODES: Record<string, typeof CODES[number]> = {
  SUCCESS: 201,
  COUNCIL: 403,
  ALREADY_SENT: 409,
  BAD_REQUEST: 422
} as const;

export const INVITE_RESULTS_MESSAGES: Record<keyof typeof INVITE_RESULTS_CODES, string> = {
  SUCCESS: 'Invite sent',
  COUNCIL: 'Invalid user',
  ALREADY_SENT: 'Invite already sent to the user',
  BAD_REQUEST: 'The email must be a valid email address.'
} as const;
