
import React from 'react';
// import { Link } from 'react-router-dom';

import * as Styled from './footer.styles';

// TODO: (FE) Define what the links will do
const Footer = () => {
  return (
    <Styled.Wrapper>
      <div>© 2021 All rights reserved.</div>
      <div>
        {/* <Link to="#">About</Link> */}
        {/* <Link to="#">Cookies</Link> */}
      </div>
    </Styled.Wrapper>
  );
};

export default Footer;
