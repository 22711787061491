import React, { useContext, useMemo } from 'react';

import { ToolContextType, ToolContext } from 'context/tool';
import { PAGE_COLORS } from 'utils/routes';

import * as Styled from './toggle.styles';

export interface ToggleProps {
  onChange(value: boolean): void;
  value: boolean;
  isReadOnly?: boolean;
}

const Toggle = ({ onChange, value, isReadOnly = false }: ToggleProps) => {
  const { step }: ToolContextType = useContext(ToolContext);
  const color = useMemo(() => step ? step.color : PAGE_COLORS.userProfile, [step]);

  const handleOnChange = () => { onChange(!value); };

  return <Styled.Wrapper onClick={handleOnChange} isActive={value} color={color} isReadOnly={isReadOnly} />;
};

export default Toggle;
