import styled from 'styled-components';

import { setTypography, sideBar } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';

export const Wrapper = styled.div<{ isHovered: boolean; color: keyof typeof colors }>`
  ${({ isHovered, color }) => sideBar(isHovered, color)}
  width: auto;
  flex: 1;
  padding: 0 300rem;
`;

export const Header = styled.div`
  height: ${layout.headerSection.h}rem;
  padding-top: ${layout.headerSection.h / 2}rem;
  margin-bottom: 60rem;
  display: flex;
  align-items: flex-end;
`;

export const SiteList = styled.div`
  flex: 1;
  padding: ${layout.contentPadding.h}rem 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const SiteListLabel = styled.div`
  ${setTypography('small')}
  opacity: 0.5;
  margin-bottom: 60rem;
`;

export const SiteListContent = styled.div`
  overflow-y: auto;
`;

export const Site = styled.div`
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 60rem;
  }

  > span {
    ${setTypography('heading3')}
  }
`;

export const SiteDetails = styled.div`
  display: flex;
  margin-left: 60rem;
`;

export const SiteDetailsShare = styled.div`
  margin-right: 100rem;
`;

export const SiteDetailsPublic = styled.div`
  display: flex;
  
  > span {
    margin-right: 40rem;
    height: 60rem;
    align-items: center;
    display: flex;
  }
`;

export const ShareUserList = styled.div`
  overflow-y: auto;
`;

export const ShareUser = styled.div`
  display: flex;
  justify-content: space-between;
  ${setTypography('small')}
  
  &:not(:last-child) {
    margin-bottom: 20rem;
  }
`;
