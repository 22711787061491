/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';

import useRouter from 'hooks/useRouter';
import useWindowSize from 'hooks/useWindowSize';
import { isMobile, isSupportedBrowser, isTablet } from 'utils/platform';
import { ROUTES } from 'utils/routes';
import * as VARS from 'utils/styles/vars';

import WindowTooSmall from './windowTooSmall/windowTooSmall';

interface NonFunctionalsProps {
  children: React.ReactNode | React.ReactNode[];
  isBypassed?: boolean;
}

const NonFunctionals = ({ children, isBypassed = false }: NonFunctionalsProps): JSX.Element => {
  const windowSize = useWindowSize();
  const [showUnsupportedBrowser, setShowUnsupportedBrowser] = useState<boolean>(false);
  const [isSubStandardBrowserAccepted, setSubstandardBrowserAccepted] = useState<boolean>(false);
  const [isTooSmall, setTooSmall] = useState<boolean>(false);
  const [shouldRotate, setRotate] = useState<boolean>(false);
  const router = useRouter();
  const currentRoute = ROUTES[router.pathname];

  useEffect(() => {
    setTooSmall(windowSize.width < VARS.breakpointDesktop || windowSize.height < VARS.desktopMinHeight);
    setRotate((isMobile() && windowSize.isLandscape) || (isTablet() && !windowSize.isLandscape));
  }, [windowSize]);

  useEffect(() => {
    if (process.env.IS_DEBUG && router.pathname.match(/^\/make-frontend-error/)) {
      throw new Error('Testing Sentry');
    }
  
    const { isSupported, needsUpgrade } = isSupportedBrowser();
    setShowUnsupportedBrowser(!isSupported || needsUpgrade);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isBypassed) return <>{children}</>;

  let errorComponent: ReactNode;
  /* if (showUnsupportedBrowser && !isSubStandardBrowserAccepted) {
    errorComponent = <UnsupportedBrowser setAccept={setSubstandardBrowserAccepted} />;
  } else if (!currentRoute) {
    errorComponent = <NotFound />;
  } */

  return (
    <>
      {errorComponent || children}
      {isTooSmall && <WindowTooSmall />}
      {/* isRendered && shouldRotate && <RotateDevice /> */}
    </>
  );
};

export default NonFunctionals;
