import React, { SyntheticEvent } from 'react';

import * as FormStyled from 'components/forms/forms.styles';
import * as Styled from './input.styles';

export interface InputProps {
  label?: string;
  type?: 'text' | 'email' | 'password';
  onChange(value: string): void;
  onSubmit?() : void;
  value: string;
  instruction?: string;
  error?: string;
  placeholder?: string;
}

const Input = ({
  label = '',
  type = 'text',
  onChange,
  onSubmit,
  value = '',
  instruction = '',
  error = '',
  placeholder = ''
}: InputProps) => {
  const handleOnChange = ({ nativeEvent: event }: SyntheticEvent) => {
    onChange((event.target as HTMLInputElement).value);
  };


  const onKeyDown = ({ nativeEvent: event }: SyntheticEvent) => {
    if ((event as KeyboardEvent).keyCode === 13) {
      event.preventDefault();
      if (onSubmit) onSubmit();
    }
  };

  return (
    <Styled.Wrapper>
      {label && <FormStyled.Label>{label}{instruction && '*'}</FormStyled.Label>}
      <Styled.Input
        onChange={handleOnChange}
        onKeyDown={onKeyDown}
        type={type}
        value={value}
        placeholder={placeholder}
        hasError={!!error}
      />
      
      {instruction && <FormStyled.Instruction>{instruction}</FormStyled.Instruction>}
      {error && <FormStyled.Error>{error}</FormStyled.Error>}
    </Styled.Wrapper>
  );
};

export default Input;
