
import React, { MutableRefObject, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';

import Button from 'components/button/button';
import IconButton from 'components/iconButton/iconButton';
import Logo from 'components/logo/logo';
import { ToolContextType, ToolContext } from 'context/tool';
import { UserContext, UserContextType } from 'context/user';
import useRouter from 'hooks/useRouter';
import { logout } from 'utils/api';
import { PAGE_COLORS, ROUTES } from 'utils/routes';

import * as Styled from './header.styles';

interface HeaderProps {
  username: string;
  title?: string;
  isUserProfile?: boolean;
  onSearchClick?(toggleState: boolean): void;
  onNotifClick?(toggleState: boolean): void;
  isSearchOpen?: boolean;
  isNotifOpen?: boolean;
  searchButtonRef?: MutableRefObject<HTMLDivElement>;
  notifButtonRef?: MutableRefObject<HTMLDivElement>;
}

const Header = ({
  username,
  title = '',
  isUserProfile = false,
  onSearchClick,
  onNotifClick,
  isSearchOpen,
  isNotifOpen,
  searchButtonRef,
  notifButtonRef
}: HeaderProps) => {
  const { setUser }: UserContextType = useContext(UserContext);
  const { step }: ToolContextType = useContext(ToolContext);
  const router = useRouter();
  const mainColor = useMemo(() => isUserProfile || !step ? PAGE_COLORS.userProfile : step.color, [isUserProfile, step]);

  const handleSearchClick = () => onSearchClick(!isSearchOpen);
  const handleNotifClick = () => onNotifClick(!isNotifOpen);

  const onLogout = async () => {
    await logout();
    setUser(null);
  };

  const onClose = () => {
    router.push(ROUTES.HOME);
  };

  return (
    <Styled.Wrapper>
      <Logo />

      <Styled.Title>{isUserProfile ? 'User Profile' : title}</Styled.Title>

      <Styled.Options>
        {username && !isUserProfile && (
          <Link to={ROUTES.USER_PROFILE}>
            <Styled.Username>{username}</Styled.Username>
          </Link>
        )}
        <Styled.Buttons>
          {isUserProfile ? (
            <>
              <Styled.Button isBigMargin>
                <Button label="Logout" color={mainColor} onClick={onLogout} />
              </Styled.Button>
              <Styled.Button>
                <IconButton icon="close" color="black" onClick={onClose} />
              </Styled.Button>
            </>
          ) : (
            <>
              <Styled.Button isActive={isSearchOpen} ref={searchButtonRef}>
                <IconButton icon={isSearchOpen ? 'close' : 'search'} color={mainColor} onClick={handleSearchClick} />
              </Styled.Button>
              <Styled.Button isActive={isNotifOpen} ref={notifButtonRef}>
                <IconButton icon={isNotifOpen ? 'close' : 'notif'} onClick={handleNotifClick} />
              </Styled.Button>
            </>
          )}
        </Styled.Buttons>
      </Styled.Options>
    </Styled.Wrapper>
  );
};

export default Header;
