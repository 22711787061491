import axios, { AxiosError, AxiosResponse } from 'axios';
import { debounce, DebouncedFunc } from 'lodash';

import { Notification } from 'components/notifications/notifications';
import {
  ParametersConfig,
  Scenario,
  ScenarioDetails,
  ScenarioSupplier,
  Scheme,
  SchemeDetails,
  Site,
  SiteDetails
} from 'context/tool.types';
import { Council, User, UserDetails } from 'context/user.types';

const mockRequest = (responseData: any) => new Promise(async (resolve) => {
  const data = await new Promise((subResolve) => subResolve(responseData));
  return resolve({ data } as AxiosResponse<any>);
}) as Promise<AxiosResponse<any>>;

// Mocked API URL: https://virtserver.swaggerhub.com/CoreBlue/YTKO/1.0.0
// Dev API URL: http://ytko-env.eba-28afru8k.eu-west-2.elasticbeanstalk.com
// Staging API URL: https://ytko.cbstaging.co.uk
const baseURL = process.env.API_URL;

const client = axios.create({ baseURL });
client.defaults.withCredentials = true;
client.defaults.headers.common = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
};

// Auth
const getCookie = () => client.get('/sanctum/csrf-cookie');

const requestLogin = (email: string, password: string) =>
  client.post('/login', { email, password });

export const login = (email: string, password: string) =>
  getCookie().then(() => requestLogin(email, password));

export const logout = () => client.post('/logout');

export const forgotPassword = (email: string) =>
  client.post('/forgot-password', { email });

export const resetPassword = (token: string, email: string, password: string, passwordConfirm: string) =>
  client.post('/reset-password', { token, email, password, password_confirmation: passwordConfirm });

export const supplierNotify = (email: string, name: string, company: string) =>
  client.post('/supplier', { email, name, company });

// Sites
const getAnalysis = () => client.get('/api/sites/analysis');

const getContexts = () => client.get('/api/sites/context');

export const getUserSites = () => client.get('/api/sites');

export const searchSite = (query: string) => client.get(`/api/sites/search?search=${query}`);

export const addSite = (siteId: Site['id']) => client.put(`/api/sites/${siteId}/public-add`);

export const removeSite = (siteId: Site['id']) => client.delete(`/api/sites/${siteId}/public-remove`);

export const requestSite = (siteId: Site['id']) => client.get(`/api/sites/${siteId}/request`);

export const createSite = (siteDetails: SiteDetails) => client.post('/api/sites', siteDetails);

export const updateSite = (siteId: Site['id'], siteDetails: SiteDetails) => client.put(`/api/sites/${siteId}`, siteDetails);

export const updateSitePublicStatus = (siteId: Site['id'], isPublic: boolean) => client.patch(`/api/sites/${siteId}`, { public_status: isPublic });

export const deleteSite = (siteId: Site['id']) => client.delete(`/api/sites/${siteId}`);

export const inviteUserToSite = (siteId: Site['id'], userEmail: User['email']) => client.post(`/api/sites/${siteId}/invite`, { email: userEmail });

export const authUserToSite = (siteId: Site['id'], userId: User['id'], isAuthorized: boolean) => client.put(`/api/sites/${siteId}/auth`, {
  user_id: userId,
  accept_request: isAuthorized
});

// Schemes
const getSchemeData = () => client.get('/api/scheme-data');

export const getParametersConfig = () => client.get<ParametersConfig>('/api/parameters_config');

export const getSiteScenarios = (siteId: Site['id']) => client.get(`/api/sites/${siteId}/scenarios`);

export const createSiteScheme = (siteId: Site['id'], schemeDetails: SchemeDetails) =>
  client.post(`/api/schemes/site/${siteId}`, schemeDetails);

export const generateSiteSceheme = (siteId: Site['id'], schemeDetails: SchemeDetails) => 
  client.post(`/api/schemes/site/${siteId}/generate`, schemeDetails);

export const getScheme = (schemeId: Scheme['id']) => client.get(`/api/schemes/${schemeId}`);

export const updateScheme = (schemeId: Scheme['id'], schemeDetails: SchemeDetails) =>
  client.put(`/api/schemes/${schemeId}`, schemeDetails);

// Scenarios
export const createScenario = (scenarioDetails: ScenarioDetails) => client.post('/api/scenarios', scenarioDetails);

export const getScenario = (scenarioId: Scenario['id']) => client.get(`/api/scenarios/${scenarioId}`);

export const updateScenario = (scenarioId: Scenario['id'], scenarioName: Scenario['name']) =>
  client.put(`/api/scenarios/${scenarioId}`, { name: scenarioName });

export const deleteScenario = (scenarioId: Scenario['id']) => client.delete(`/api/scenarios/${scenarioId}`);

export const getScenarioSuppliers = (scenarioId: Scenario['id']) => client.get<ScenarioSupplier[]>(`/api/scenarios/${scenarioId}/suppliers`);

// User
export const getUser = () => client.get('/api/user');

export const updateUser = (userDetails: UserDetails) => client.put('/api/user', userDetails);

// Council
// TODO: (BE) Needs to be created
// export const getCouncils = () => client.get('/api/councils');
export const getCouncils = () => {
  const council: Council[] = [
    {
      id: 1,
      name: 'Bristol City Council'
    },
    {
      id: 2,
      name: 'Brighton City Council'
    },
    {
      id: 3,
      name: 'Darby City Council'
    },
    {
      id: 4,
      name: 'Leeds City Council'
    },
    {
      id: 5,
      name: 'Southampton City Council'
    }
  ];

  return mockRequest(council);
};

export const getNotifications = () => client.get<Notification[]>('/api/user/notifications');

// Data
const loadData = () => Promise.all([
  getAnalysis(),
  getContexts(),
  getParametersConfig(),
  getSchemeData()
]);

export const fetchData = async () => {
  const responses = await loadData();
  const [
    analysis,
    contexts,
    parametersConfig,
    schemeData
  ] = await Promise.all(responses.map((response) => response.data));

  const {
    accreditations,
    max_no_units: maxNoUnits,
    renewable_technologies: renewableTechnologies,
    dwelling_types: dwellingTypes,
    ndss: nDSS,
    design_adaptabilities: designAdaptabilities,
    mmc_categories: mmcCategories,
    material_qualities: materialQualities,
    energy_efficiencies: energyEfficiencies,
    energy_performances: energyPerformances,
    total_kpis: totalKpis,
    matching_kpis: matchingKpis,
    temp_id: tempID
  } = await schemeData;

  const data = {
    analysis,
    contexts,
    accreditations,
    maxNoUnits,
    renewableTechnologies,
    dwellingTypes,
    nDSS,
    designAdaptabilities,
    mmcCategories,
    materialQualities,
    energyEfficiencies,
    energyPerformances,
    totalKpis,
    matchingKpis,
    parametersConfig,
    tempID
  };

  return data;
};

// Error handling
export const parseFormErrors = (error: AxiosError) => {
  const parsedErrors: { message: string; fields: Record<string, string> } = {
    message: error.message || 'An error occurred',
    fields: {}
  };

  if (error.response && error.response.data) {
    const errorData = error.response.data;
    parsedErrors.message = errorData.message;

    if (errorData.errors) {
      parsedErrors.fields = Object.fromEntries(
        Object.entries(errorData.errors).map(([key, value]: [string, string[]]) => [key, value.join(' ')])
      );
    }
  }

  return parsedErrors;
};

// Debounce Utils
export const DEFAULT_TIMEOUT = 1000;
export const DEFAULT_MAX_TIMEOUT = 5000;

/**
 * Debounce a callback function
 * @param callback The callback function
 * @param delay The delay in Ms, 1000ms by default
 * @param maxWait The maximum wait in Ms, 5000 by default
 * @returns
 */
 export function getDebouncedCall<C extends(...args: any[]) => any>(
  callback: C,
  delay = DEFAULT_TIMEOUT,
  maxWait = DEFAULT_MAX_TIMEOUT
): DebouncedFunc<C> {
  return debounce(callback, delay, { maxWait });
}

export const callWithSpinnerTimeout = async (
  callback: (...args: any[]) => Promise<any>,
  timeout: number,
  setReady: (state: boolean) => void
) => {
  // If the update takes too long, show a spinner
  if (timeout) window.clearTimeout(timeout);
  timeout = window.setTimeout(() => {
    timeout = null;
    setReady(false);
  }, DEFAULT_TIMEOUT);

  const response = await callback();

  if (timeout) window.clearTimeout(timeout);
  if (timeout === null) setReady(true);

  return response;
};
