/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { AxiosError } from 'axios';

import Button from 'components/button/button';
import IconButton from 'components/iconButton/iconButton';
import Input from 'components/input/input';
import { modalAnimConfig } from 'context/modal';
import { PAGE_COLORS } from 'utils/routes';

import * as Styled from './../userProfile/authorizations/userAuthorizations.styles';
import * as UserProfileStyled from './../userProfile/userProfile.styles';
import { User } from 'context/user.types';
import { Site } from 'context/tool.types';
import useUserSites from 'hooks/useUserSites';

import {
  authUserToSite,
  inviteUserToSite,
  parseFormErrors
} from 'utils/api';

import {
  AUTH_RESULTS_CODES,
  AUTH_RESULTS_MESSAGES,
  CODES,
  INVITE_RESULTS_CODES,
  INVITE_RESULTS_MESSAGES,
} from './../userProfile/authorizations/userAuthorizations.config';

type PendingAuthorizedUser = Site['users'][number] & {
  shouldAuthorize: boolean;
};

const getAuthErrorMessage = (
  errorCode: typeof AUTH_RESULTS_CODES[keyof typeof AUTH_RESULTS_CODES]
) => {
  const errorStatus = Object.entries(AUTH_RESULTS_CODES).find(
    ([status, code]) => code === errorCode
  ) as [keyof typeof AUTH_RESULTS_CODES, typeof errorCode];
  if (errorStatus) return AUTH_RESULTS_MESSAGES[errorStatus[0]];
  return null;
};

const getInviteErrorMessage = (
  errorCode: typeof INVITE_RESULTS_CODES[keyof typeof INVITE_RESULTS_CODES]
) => {
  const errorStatus = Object.entries(INVITE_RESULTS_CODES).find(
    ([status, code]) => code === errorCode
  ) as [keyof typeof INVITE_RESULTS_CODES, typeof errorCode];
  if (errorStatus) return INVITE_RESULTS_MESSAGES[errorStatus[0]];
  return null;
};

export interface UserInvite {}
export interface UserInviteProps {
  user: User;
  sharedSite: Site;
  onSetSharedSite: (site: Site) => void;
}
const UserInvite = ({ user, sharedSite, onSetSharedSite }: UserInviteProps) => {
  const [newUserEmail, setNewUserEmail] = useState<User['email']>('');
  const [authErrorMessage, setAuthErrorMessage] = useState<string>('');
  const [inviteErrorMessage, setInviteErrorMessage] = useState<string>('');
  const [inviteSuccessMessage, setInviteSuccessMessage] = useState<string>('');
  const [authorizedUsers, setAuthorizedUsers] = useState<
    PendingAuthorizedUser[]
  >([]);
  const { fetchUserSites } = useUserSites();

  const onShare = useCallback(async () => {
    try {
      const requests = authorizedUsers
        .filter(
          (authorizedUser) =>
            (authorizedUser.shouldAuthorize &&
              !sharedSite.users.find(
                (siteUser) => siteUser.id === authorizedUser.id
              )) ||
            (!authorizedUser.shouldAuthorize &&
              sharedSite.users.find(
                (siteUser) => siteUser.id === authorizedUser.id
              ))
        )
        .map((authorizedUser) =>
          authUserToSite(
            sharedSite.id,
            authorizedUser.id,
            authorizedUser.shouldAuthorize
          )
        );

      if (requests.length) await Promise.all(requests);
      fetchUserSites();

      // Close the modal
      onSetSharedSite(null);
    } catch (error) {
      const axiosError = error as AxiosError;

      const knownErrorMessage = getAuthErrorMessage(
        axiosError.response.status as typeof CODES[number]
      );
      if (knownErrorMessage) {
        setAuthErrorMessage(knownErrorMessage);
        return;
      }

      const parsedErrors = parseFormErrors(error);
      setAuthErrorMessage(parsedErrors.message);
    }
  }, [authorizedUsers, fetchUserSites, sharedSite]);

  const authorizeNewUser = useCallback(async () => {
    const newArray = [...authorizedUsers];
    const existingUser = authorizedUsers.find(
      (authorizedUser) => authorizedUser.email === newUserEmail
    );

    if (existingUser) {
      // Reauthorize the user if they've locally been unauthorized
      if (!existingUser.shouldAuthorize) {
        const userIndex = newArray.findIndex(
          (authorizedUser) => authorizedUser.id === existingUser.id
        );
        newArray[userIndex].shouldAuthorize = true;
        existingUser.shouldAuthorize = true;
      } else {
        // Ignore otherwise
        return;
      }
    } else {
      try {
        await inviteUserToSite(sharedSite.id, newUserEmail);
        setInviteSuccessMessage(`Invite successfully sent to ${newUserEmail}`);
      } catch (error) {
        const axiosError = error as AxiosError;

        const knownErrorMessage = getInviteErrorMessage(
          axiosError.response.status as typeof CODES[number]
        );
        if (knownErrorMessage) {
          setInviteErrorMessage(knownErrorMessage);
          return;
        }

        const parsedErrors = parseFormErrors(error);
        setInviteErrorMessage(parsedErrors.message);
      }
    }

    setAuthorizedUsers(newArray);
    setNewUserEmail('');
  }, [authorizedUsers, newUserEmail, sharedSite]);

  const unauthorizeUser = useCallback(
    (userId: User['id']) => {
      const newArray = [...authorizedUsers];
      const userIndex = newArray.findIndex(
        (authorizedUser) => authorizedUser.id === userId
      );
      const unauthorizedUser = newArray[userIndex];
      unauthorizedUser.shouldAuthorize = false;

      setAuthorizedUsers(newArray);
    },
    [authorizedUsers]
  );

  useEffect(() => {
    // Initialise authorised users temp array
    if (!sharedSite) {
      setAuthorizedUsers([]);
      setNewUserEmail('');
      setAuthErrorMessage('');
      setInviteErrorMessage('');
      setInviteSuccessMessage('');
    } else if (!authorizedUsers.length) {
      const filteredUsers = [...sharedSite.users]
        // Don't count the current user
        .filter((authorizedUser) => authorizedUser.id !== user.id)
        // Set local authorisation flag on
        .map((authorizedUser: PendingAuthorizedUser) => {
          authorizedUser.shouldAuthorize = true;
          return authorizedUser;
        });

      setAuthorizedUsers(filteredUsers);
    }
  }, [authorizedUsers.length, sharedSite, user.id]);

  return (
    <UserProfileStyled.Modal {...modalAnimConfig}>
      <UserProfileStyled.ModalClose>
        <IconButton
          icon="close"
          color="black"
          onClick={() => onSetSharedSite(null)}
        />
      </UserProfileStyled.ModalClose>

      <UserProfileStyled.ModalTitle>
        Share {sharedSite.name}
      </UserProfileStyled.ModalTitle>

      {(authErrorMessage || inviteSuccessMessage) && (
        <UserProfileStyled.ModalMessage isError={!!authErrorMessage}>
          {authErrorMessage || inviteSuccessMessage}
        </UserProfileStyled.ModalMessage>
      )}

      <UserProfileStyled.ModalSection>
        <UserProfileStyled.ModalInput hasError={!!inviteErrorMessage}>
          <Input
            type="email"
            label="Add Users"
            value={newUserEmail}
            placeholder="Enter user email"
            onChange={(value) => {
              setNewUserEmail(value);
              if (inviteSuccessMessage) setInviteSuccessMessage('');
              if (inviteErrorMessage) setInviteErrorMessage('');
            }}
            onSubmit={authorizeNewUser}
            error={inviteErrorMessage}
          />
          <IconButton
            icon="plus"
            color={PAGE_COLORS.userProfile}
            onClick={authorizeNewUser}
          />
        </UserProfileStyled.ModalInput>
      </UserProfileStyled.ModalSection>

      <UserProfileStyled.ModalSection isScrollable>
        <UserProfileStyled.ModalSectionLabel>
          Users Invited
        </UserProfileStyled.ModalSectionLabel>
        <Styled.ShareUserList>
          {authorizedUsers
            .filter((authorizedUser) => authorizedUser.shouldAuthorize)
            .map((authorizedUser) => (
              <Styled.ShareUser key={authorizedUser.id}>
                {authorizedUser.email}
                <IconButton
                  icon="close"
                  color="black"
                  onClick={() => unauthorizeUser(authorizedUser.id)}
                  isSmall
                />
              </Styled.ShareUser>
            ))}
          {!authorizedUsers.filter(
            (authorizedUser) => authorizedUser.shouldAuthorize
          ).length
            ? 'No users'
            : ''}
        </Styled.ShareUserList>
      </UserProfileStyled.ModalSection>

      <Button label="Done" onClick={onShare} isThin />
    </UserProfileStyled.Modal>
  );
};

export default UserInvite;
