import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { getStoredUser, setStoredUser } from 'utils/localStorage';

import { User } from './user.types';

interface UserProviderProps {
  children: ReactNode | ReactNode[];
}

export interface UserContextType {
  user: User;
  setUser(patch: { [k in keyof User]?: User[k] }): void;
}

const defaultContext: UserContextType = {
  user: null,
  setUser: () => null
};

export const UserContext: React.Context<UserContextType> =
  createContext<UserContextType>(defaultContext);

export const UserProvider = ({ children }: UserProviderProps): JSX.Element => {
  const [user, setUser] = useState<UserContextType['user']>(getStoredUser() || defaultContext.user);

  const patchUser: UserContextType['setUser'] = useCallback((patch = {}) => {
    if (patch === null) setUser(null);
    else setUser({ ...(user || {}), ...patch } as User);
  }, [user]);

  useEffect(() => {
    setStoredUser(user);
  }, [user]);

  const contextValue: UserContextType = useMemo(() => ({
    user,
    setUser: patchUser,
  }), [user, patchUser]);

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};
