import React, { useState } from 'react';

import * as Styled from './version.styles';

const Version = () => {
  const [isOpen, setIsOpen] = useState<boolean>(!!process.env.IS_DEBUG);
  const onToggle = () => setIsOpen((state) => !state);

  return (
    <Styled.Wrapper>
      {isOpen && (
        <Styled.Info>
          {`v. ${process.env.VERSION} - (${process.env.ENV}),
              built on ${new Date(process.env.BUILD_DATE).toUTCString()}
          `}
        </Styled.Info>
      )}
      <Styled.Button onClick={onToggle}>[ {isOpen ? '-' : '+'} ]</Styled.Button>
    </Styled.Wrapper>
  );
};

export default React.memo(Version);
