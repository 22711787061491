import { FlattenSimpleInterpolation } from 'styled-components';

import { getViewportInfo } from 'utils/dom';
import { isMobile } from 'utils/platform';

import * as VARS from './vars';

export const mqMobile = `(max-width: ${VARS.breakpointTablet - 1}px)`;
export const mediaMobile = (content: string) =>
  `@media ${mqMobile} {
    ${content}
  }`;

export const mqMobileSmallHeight = `(max-height: ${VARS.breakpointMobileSmallHeight}px)`;
export const mediaMobileSmallHeight = (content: string) =>
  `@media ${mqMobileSmallHeight} {
    ${content}
  }`;

export const mqTablet = `(min-width: ${VARS.breakpointTablet}px)`;
export const mediaTablet = (content: string | FlattenSimpleInterpolation) =>
  `@media ${mqTablet} {
    ${content}
  }`;

export const mqDesktop = `(min-width: ${VARS.breakpointDesktop}px) and (min-height: ${VARS.desktopMinHeight}px)`;
export const mediaDesktop = (content: string) =>
  `@media ${mqDesktop} {
    ${content}
  }`;

const mqDesktopLarge = `(min-width: ${VARS.breakpointDesktopLarge}px)`;
export const mediaDesktopLarge = (content: string) =>
  `@media ${mqDesktopLarge} {
    ${content}
  }`;

export const mqDesktopWide = `(min-width: ${VARS.breakpointDesktop}px) and (min-aspect-ratio: ${VARS.desktopWideAspectRatio})`;
export const mediaDesktopWide = (content: string) =>
  `@media ${mqDesktopWide} {
    ${content}
  }`;

export const mqLandscape = '(orientation: landscape)';
export const mediaLandscape = (content: string) =>
  `@media ${mqLandscape} {
    ${content}
  }`;

export const mqPortrait = '(orientation: portrait)';
export const mediaPortrait = (content: string) =>
  `@media ${mqPortrait} {
    ${content}
  }`;

export const matchMobile = {
  match: window.matchMedia(mqMobile),
  scalableFontSize: VARS.mobileScalableFontSize,
  minFontSize: VARS.mobileMinFontSize,
  setVh: false
};

export const matchMobileSmallHeight = {
  match: window.matchMedia(mqMobileSmallHeight),
  scalableFontSize: VARS.mobileSmallHeightScalableFontSize,
  minFontSize: VARS.mobileMinFontSize,
  setVh: false
};

export const matchTablet = {
  match: window.matchMedia(mqTablet),
  scalableFontSize: VARS.tabletScalableFontSize,
  minFontSize: VARS.desktopMinFontSize,
  setVh: false
};

export const matchDesktop = {
  match: window.matchMedia(mqDesktop),
  scalableFontSize: VARS.desktopScalableFontSize,
  minFontSize: VARS.desktopMinFontSize,
  setVh: false
};

export const matchDesktopWide = {
  match: window.matchMedia(mqDesktopWide),
  scalableFontSize: VARS.desktopWideScalableFontSize,
  minFontSize: VARS.desktopMinFontSize,
  setVh: true
};

export const isMobileLayout = () => isMobile() || getViewportInfo().width < VARS.breakpointTablet;

export const setScalableFontSize = () => {
  let result = '';

  result += `font-size: ${VARS.mobileScalableFontSize}vw;`;

  result += mediaMobileSmallHeight(`font-size: ${VARS.mobileSmallHeightScalableFontSize}vw;`);

  result += mediaTablet(`
    font-size: ${VARS.tabletScalableFontSize}vw;
  `);

  result += mediaDesktop(`
    font-size: ${VARS.desktopScalableFontSize}vw;
  `);

  result += mediaDesktopWide(`font-size: ${VARS.desktopWideScalableFontSize}vw;`);

  return result;
};
