import React, { useContext, useEffect } from 'react';

import UserProfileLayout from 'components/layouts/userProfile/userProfileLayout';
import UserAuthorizations from 'components/userProfile/authorizations/userAuthorizations';
import UserInfo from 'components/userProfile/info/userInfo';
import { UserContextType, UserContext } from 'context/user';
import useData from 'hooks/useData';
import useHoveredStates from 'hooks/useHoveredStates';
import useUserSites from 'hooks/useUserSites';
import useRouter from 'hooks/useRouter';
import { ROUTES } from 'utils/routes';

import * as Styled from './userProfile.styles';

const UserProfilePage = () => {
  const { user }: UserContextType = useContext(UserContext);
  const { hoveredStates, updateHoveredStates } = useHoveredStates('sidebar');

  const router = useRouter();
  useUserSites(true);
  useData(true);

  useEffect(() => {
    if (!user) router.push(ROUTES.HOME);
  }, [user, router]);

  if (!user) return null;

  return (
    <UserProfileLayout>
      <Styled.Wrapper>
        <UserInfo
          isHovered={hoveredStates.sidebar}
          onHoverChange={(state) => updateHoveredStates('sidebar', state)}
        />
        <UserAuthorizations
          isHovered={hoveredStates.content}
          onHoverChange={(state) => updateHoveredStates('content', state)}
        />
      </Styled.Wrapper>
    </UserProfileLayout>
  );
};

export default UserProfilePage;
