import styled from 'styled-components';
import { rgba } from 'polished';
import { motion } from 'framer-motion';

import { hover, input, setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div<{ isDisabled: boolean }>`
  position: relative;
  z-index: 1;

  ${({ isDisabled }) => isDisabled ? `
    cursor: not-allowed;

    > * {
      pointer-events: none;
    }
  ` : ''}
`;

export const DropdownMenu = styled.div<{ hasError?: boolean; isOpen: boolean }>`
  ${({ hasError }) => input(hasError)}
  min-height: 2.25em;
  cursor: pointer;
  z-index: 1;
  background: ${colors.white};

  svg {
    position: absolute;
    height: 30%;
    transform: ${({ isOpen }) => `translateY(${isOpen ? 20 : 0}%) rotate(${isOpen ? 90 : -90 }deg)`};
    top: 50%;
    right: 0;
    width: 60rem;
    margin-top: -0.25em;
    transition: transform 0.2s ease-out;
  }

  ${({ isOpen }) => hover(`
    svg {
      transform: translateY(${isOpen ? 0 : 20}%) rotate(${isOpen ? 90 : -90}deg);
    }
  `)}
`;

export const DropdownMenuOptions = styled(motion.div)`
  position: absolute;
  width: 100%;
  top: 100%;
  max-height: 6em;
  background: ${colors.white};
  border: 1px solid ${rgba(colors.black, 0.2)};
  border-top: transparent;
  border-radius: 0 0 30rem 30rem;
  overflow-y: auto;
  z-index: 0;
  ${setTypography('input')}
`;

export const DropdownMenuOption = styled.div<{ isSelected: boolean; isReadOnly: boolean }>`
  min-height: 2.25em;
  padding: 10rem 30rem;
  cursor: ${({ isSelected, isReadOnly }) => !isReadOnly || isSelected ? 'pointer' : 'not-allowed'};

  ${({ isSelected }) => !isSelected ? `
    color: ${rgba(colors.black, 0.2)};
  ` : ''}

  transition: opacity 0.2s ease-out, background 0.2s ease-out;

  ${hover(`
    background: ${rgba(colors.black, 0.025)}
  `)}
`;
