export const debounce = (
  fn: (...args: any[]) => void,
  delay = 250,
  hasLeadingCall = false,
  hasTrailingCall = false
) => {
  let timeout: number;

  const cancel = () => {
    window.clearTimeout(timeout);
    timeout = null;
  };

  const debounced = (...args: any[]) => {
    const shouldCallNow = hasLeadingCall && !timeout;
    const doLater = () => {
      timeout = null;

      if (!hasLeadingCall) fn(...args);
      if (hasTrailingCall) {
        timeout = window.setTimeout(() => {
          timeout = null;
          fn(...args);
        }, delay);
      }
    };

    window.clearTimeout(timeout);
    timeout = window.setTimeout(doLater, delay);

    if (shouldCallNow) fn(...args);
  };

  debounced.cancel = cancel;
  return debounced;
};
