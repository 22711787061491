

export default `
  @font-face {
    font-family: 'Manrope';
    font-weight: 300;
    font-style: normal;
    font-display: block;
    src: url('/fonts/manrope/Manrope-ExtraLight.woff2') format('woff2'),
      url('/fonts/manrope/Manrope-ExtraLight.woff') format('woff');
  }

  @font-face {
    font-family: 'Manrope';
    font-weight: 400;
    font-style: normal;
    font-display: block;
    src: url('/fonts/manrope/Manrope-Light.woff2') format('woff2'),
      url('/fonts/manrope/Manrope-Light.woff') format('woff');
  }

  @font-face {
    font-family: 'Manrope';
    font-weight: 500;
    font-style: normal;
    font-display: block;
    src: url('/fonts/manrope/Manrope-Regular.woff2') format('woff2'),
      url('/fonts/manrope/Manrope-Regular.woff') format('woff');
  }

  @font-face {
    font-family: 'Manrope';
    font-weight: 600;
    font-style: normal;
    font-display: block;
    src: url('/fonts/manrope/Manrope-Medium.woff2') format('woff2'),
      url('/fonts/manrope/Manrope-Medium.woff') format('woff');
  }

  @font-face {
    font-family: 'Manrope';
    font-weight: 700;
    font-style: normal;
    font-display: block;
    src: url('/fonts/manrope/Manrope-SemiBold.woff2') format('woff2'),
      url('/fonts/manrope/Manrope-SemiBold.woff') format('woff');
  }

  @font-face {
    font-family: 'Manrope';
    font-weight: 800;
    font-style: normal;
    font-display: block;
    src: url('/fonts/manrope/Manrope-Bold.woff2') format('woff2'),
      url('/fonts/manrope/Manrope-Bold.woff') format('woff');
  }

  @font-face {
    font-family: 'Manrope';
    font-weight: 900;
    font-style: normal;
    font-display: block;
    src: url('/fonts/manrope/Manrope-ExtraBold.woff2') format('woff2'),
      url('/fonts/manrope/Manrope-ExtraBold.woff') format('woff');
  }
`;
