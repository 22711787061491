import React from 'react';

import LogoSrc from 'assets/images/logo.jpg';

import * as Styled from './logo.styles';

interface LogoProps {
  isFloating?: boolean;
}

const Logo = ({ isFloating = false }: LogoProps) => {
  if (isFloating) {
    return (
      <Styled.Wrapper isFloating={isFloating}>
        <img src={LogoSrc} style={{ height: '100%' }} />
      </Styled.Wrapper>
    );
  }
  return (
    <>
      <img src={LogoSrc} style={{ height: '70%' }} />
    </>
  );
};

export default Logo;
