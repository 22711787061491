import { useCallback, useContext, useEffect } from 'react';

import { ToolContextType, ToolContext } from 'context/tool';
import { Site } from 'context/tool.types';
import { getUserSites } from 'utils/api';

const useUserSites = (shouldFetchOnMount = false) => {
  const { setSites }: ToolContextType = useContext(ToolContext);

  const fetchUserSites = useCallback(async () => {
    const sitesResponse = await getUserSites();
    const sites: Site[] = await sitesResponse.data;
    setSites(sites);

    return sites;
  }, [setSites]);

  useEffect(() => {
    if (shouldFetchOnMount) fetchUserSites();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    fetchUserSites
  };
};

export default useUserSites;
