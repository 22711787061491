import { useCallback, useContext, useEffect } from 'react';

import { ToolContextType, ToolContext } from 'context/tool';
import { fetchData } from 'utils/api';

const useData = (shouldFetchOnMount = false) => {
  const { setData }: ToolContextType = useContext(ToolContext);

  const updateData = useCallback(async () => {
    const data = await fetchData();
    setData(data);

    return data;
  }, [setData]);

  useEffect(() => {
    if (shouldFetchOnMount) fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    fetchData: updateData
  };
};

export default useData;
