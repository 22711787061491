import styled from 'styled-components';

import { hover, isDarkColor, setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

import { IconButtonProps } from './iconButton';

export const Wrapper = styled.button<{
  color: IconButtonProps['color'];
  isSmall: IconButtonProps['isSmall'];
  icon: IconButtonProps['icon'];
  isBigIcon: boolean
}>`
  position: relative;
  color: ${({ color }) => isDarkColor(color) ? colors.white : 'inherit'};
  z-index: 0;

  ${setTypography('button')};
  width: ${({ isSmall }) => isSmall ? 40 : 65}rem;
  height: ${({ isSmall }) => isSmall ? 40 : 65}rem;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.2s ease-out;

  svg {
    height: ${({ isSmall, isBigIcon }) => isBigIcon ? 60 : isSmall ? 55 : 40}%;
    ${({ icon }) => `
      ${icon === 'notif' ? 'margin-left: -7.5%;' : ''}
    `}
  }

  &:disabled {
    opacity: 0.5;
  }
`;

export const Background = styled.div<{ color: IconButtonProps['color'] }>`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 50%;
  background: ${({ color }) => colors[color]};
  z-index: -1;
  transition: transform 0.2s ease-out, background 0.2s ease-out;

  ${hover(`
    transform: scale(1.01);
  `)}
`;
