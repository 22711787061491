export const ROUTES = {
  HOME: '/',
  RESET_PASSWORD: '/reset-password',
  USER_PROFILE: '/user'
} as const;

export const PAGE_COLORS = {
  home: {
    lgo: 'anakiwa',
    mmc: 'ronchi',
  },
  tool: {
    lgo: {
      specs: 'anakiwa',
      scheme: 'yellowGreen',
    },
    mmc: {
      profile: 'ronchi',
      kpi: 'mauve',
      products: 'algaeGreen'
    }
  },
  userProfile: 'flesh'
} as const;

export const TOOL_STEPS = {
  lgo: [
    {
      id: 'lgoSpecs',
      name: 'Site Specs',
      color: PAGE_COLORS.tool.lgo.specs,
      icon: 'specs'
    },
    {
      id: 'lgoScheme',
      name: 'Scheme Configurator',
      color: PAGE_COLORS.tool.lgo.scheme,
      icon: 'config'
    }
  ],
  mmc: [
    {
      id: 'mmcProfile',
      name: 'Company Profile',
      color: PAGE_COLORS.tool.mmc.profile,
      icon: 'companyProfile'
    },
    {
      id: 'mmcKPI',
      name: 'Company KPI',
      color: PAGE_COLORS.tool.mmc.kpi,
      icon: 'config'
    },
    {
      id: 'mmcProducts',
      name: 'Products',
      color: PAGE_COLORS.tool.mmc.products,
      icon: 'products'
    }
  ]
} as const;
