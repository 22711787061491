import styled from 'styled-components';
import { rgba } from 'polished';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div``;

export const Button = styled.div`
  margin-top: 120rem;
`;

export const Input = styled.div`
  margin-bottom 80rem;
`;

export const Description = styled.p`
  color: ${rgba(colors.black, 0.5)};
  margin-bottom 80rem;
`;

export const ErrorMessage = styled.div`
  color: ${colors.atomicTangerine};
  margin-bottom 80rem;
`;

export const SuccessMessage = styled.div`
  color: ${colors.algaeGreen};
`;

export const Label = styled.div`
  ${setTypography('small')};
  color: ${rgba(colors.black, 0.5)};
`;

export const Instruction = styled.div`
  ${setTypography('small')};
  margin-top: 20rem;
  color: ${rgba(colors.black, 0.5)};
`;

export const Error = styled.div`
  ${setTypography('small')};
  margin-top: 20rem;
  color: ${colors.atomicTangerine};
`;
