import styled from 'styled-components';
import { rgba } from 'polished';

import { setVh } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';

export const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: ${setVh(layout.header.h)};
  padding: ${layout.pagePadding.h / 4}px ${layout.pagePadding.w / 4}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${rgba(colors.black, layout.lineOpacity)};
`;

export const Title = styled.h1`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Options = styled.div`
  display: flex;
  align-items: center;
`;

export const Username = styled.div`
  margin-right: 45rem;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
`;

export const Button = styled.div<{ isActive?: boolean; isBigMargin?: boolean }>`
  ${({ isActive }) => isActive ? `
    z-index: 12;
  ` : ''}

  button {
    height: 60rem;
    width: 60rem;
  }

  &:not(:last-child) {
    margin-right: ${({ isBigMargin }) => isBigMargin ? 60 : 20}rem;
  }
`;
