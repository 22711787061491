import React, { ReactNode, useContext } from 'react';

import Header from 'components/header/header';
import Footer from 'components/footer/footer';
import { UserContextType, UserContext } from 'context/user';

import * as Styled from './../layouts.styles';

interface UserProfileLayoutProps {
  children: ReactNode | ReactNode[];
}

const UserProfileLayout = ({ children }: UserProfileLayoutProps) => {
  const { user }: UserContextType = useContext(UserContext);

  return (
    <Styled.Wrapper>
      <Header
        username={user.name}
        title="User Profile"
        isUserProfile
      />

      <Styled.Content isColumn>
        <Styled.Page hasBottomBorder hasFooter>{children}</Styled.Page>
        <Styled.Footer>
          <Footer />
        </Styled.Footer>
      </Styled.Content>
    </Styled.Wrapper>
  );
};

export default UserProfileLayout;
