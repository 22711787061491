import { fromPairs } from 'lodash';

export const parseUrlQuery = (url = window.location.search) =>
  fromPairs(
    url
      .replace('?', '')
      .split('&')
      .filter(Boolean)
      .map((parameter) => parameter.split('='))
  );

export const toQueryString = (queryObject: Record<string, string>) =>
  `${Object.keys(queryObject).length ? '?' : ''}${Object.entries(queryObject).map(([key, value]) => `${key}=${value}`).join('&')}`;
