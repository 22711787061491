import styled from 'styled-components';

import { layout } from 'utils/styles/theme';

export const Wrapper = styled.div<{ isFloating?: boolean }>`
  ${({ isFloating }) => isFloating ? `
    position: absolute;
    top: 0;
    left: 0;
    padding: ${layout.pagePadding.h}rem 0 0 ${layout.pagePadding.w}rem;
    opacity: 0;
    animation: fadeIn 0.75s ease-out 0.75s normal forwards;
  ` : ''}

  img {
    width: 160rem;
  }
  @keyframes fadeIn {
    from {
      opacity:0;
    }
    to {
      opacity:1;
    }
  }
`;