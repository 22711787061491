import React, { Ref, SyntheticEvent, useMemo } from 'react';

import { colors } from 'utils/styles/theme';

import * as Styled from './iconButton.styles';

export const validIcons = [
  'add',
  'arrow',
  'backgroundMask',
  'chevron',
  'close',
  'companyProfile',
  'config',
  'download',
  'edit',
  'info',
  'message',
  'minus',
  'notif',
  'plus',
  'products',
  'search',
  'share',
  'specs',
  'tick'
] as const;

export interface IconButtonProps {
  icon: typeof validIcons[number];
  onClick?(event: SyntheticEvent): void;
  onBlur?(event: SyntheticEvent): void;
  color?: keyof typeof colors;
  isSmall?: boolean;
  isDisabled?: boolean;
  ref?: Ref<HTMLButtonElement>;
}

const IconButton = ({
  icon,
  onClick,
  onBlur,
  color = 'black',
  isSmall = false,
  isDisabled = false,
}: IconButtonProps) => {
  const SvgIcon = useMemo(() => {
    if (!icon) return null;
    const req = require.context('!@svgr/webpack!svgs/', false, /\.svg$/);
    return req(`./${icon}.svg`).default;
  }, [icon]);
  
  return (
    <Styled.Wrapper
      {...(onClick ? { onClick } : {})}
      {...(onBlur ? { onBlur } : {})}
      color={color}
      isSmall={isSmall}
      icon={icon}
      isBigIcon={icon === 'close'}
      disabled={isDisabled}
    >
      <Styled.Background color={color} />
      <SvgIcon />
    </Styled.Wrapper>
  );
};

export default IconButton;
