import { rgba } from 'polished';
import styled from 'styled-components';

import { easeInOutCubic, isDarkColor } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

const ease = easeInOutCubic;

export const Wrapper = styled.div<{ isActive: boolean; color: keyof typeof colors, isReadOnly: boolean }>`
  position: relative;
  cursor: pointer;
  width: 140rem;
  height: 60rem;
  background: ${({ isActive, color }) => isActive ? colors[color] : rgba(colors.black, 0.1)};
  border-radius: 100rem;
  transition: background 0.4s ${ease};

  &:before, &:after {
    content: '';
    position: absolute;
    border-radius: 50%;
    height: 60rem;
    width: 60rem;
  }

  &:before {
    background: ${({ isActive }) => isActive ? 'transparent' : colors.black};
    transition: transform 0.4s ${ease}, background 0.3s ${ease};
  
    ${({ isActive }) => isActive ? `
      transform: translateX(125%);
    ` : ''}
  }

  &:after {
    background: ${({ isActive, color }) => isActive && !isDarkColor(color) ? colors.black : colors.white};
    transform: ${({ isActive }) => isActive ? 'translateX(125%)' : ''} scale(0.4);
    transition: transform 0.4s ${ease}, background 0.4s ${ease};
  }

  ${({ isReadOnly }) => isReadOnly && `
    opacity: 60%;
    pointer-events: none;
  `}
`;
