import styled from 'styled-components';
import { rgba } from 'polished';
import { motion } from 'framer-motion';

import { setVh } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${setVh(100)};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Overlay = styled.div`
  position: fixed;
  z-index: 11;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: ${rgba(colors.white, 0.5)};
`;
