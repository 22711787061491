import { useCallback, useContext, useEffect } from 'react';
import { ModalContextType, ModalContext } from 'context/modal';

const useModal = (
  modalId: ModalContextType['currentModalId'],
  content: ModalContextType['content'],
  isActive: boolean,
  portal?: ModalContextType['portal'],
  onClose?: () => void,
  dismiss?: () => void
) => {
  const { currentModalId, setModal }: ModalContextType = useContext(ModalContext);

  const disposeModal = useCallback(() => {
    if (currentModalId && currentModalId === modalId) setModal('');
  }, [currentModalId, setModal, modalId]);

  useEffect(() => {
    if (
      // Initialise the modal
      (isActive && currentModalId !== modalId) ||
      // Update if/when values change
      (currentModalId === modalId && isActive)
    ) {
      setModal(modalId, content, portal, onClose, dismiss);
    } else if (!isActive && currentModalId && currentModalId === modalId) {
      // Dispose of the modal
      setModal('');
    }
  }, [currentModalId, isActive, content, modalId, portal, setModal, onClose, dismiss]);

  return {
    disposeModal
  };
};

export default useModal;
