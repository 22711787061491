import styled from 'styled-components';
import { rgba } from 'polished';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.footer`
  width: 100%;
  ${setTypography('small')};
  color: ${rgba(colors.black, 0.5)};
  display: flex;
  justify-content: space-between;
  align-items: center;

  a:not(:last-child) {
    margin-right: 60rem;
  }
`;