import { rgba } from 'polished';
import styled from 'styled-components';

import { setTypography, sideBar } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';


export const Wrapper = styled.div<{ isHovered: boolean; color: keyof typeof colors }>`
  ${({ isHovered, color }) => sideBar(isHovered, color)}
  width: 40%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: ${layout.headerSection.h}rem;
  padding: ${layout.headerSection.h / 2}rem 100rem 0 300rem;
  margin-bottom: 60rem;
  position: relative;
`;

export const HeaderButton = styled.div`
  margin-right: 60rem;
`;

export const HeaderName = styled.input<{ hasError: boolean }>`
  ${setTypography('heading2')}
  transition: color 0.2s ease-out;

  &::placeholder {
    transition: color 0.2s ease-out;
  }

  ${({ hasError }) => hasError ? `
    color: ${colors.atomicTangerine};

    &::placeholder {
      color: ${rgba(colors.atomicTangerine, 0.5)};
    }
  ` : ''}
`;

export const HeaderError = styled.div`
  color: ${colors.atomicTangerine};
  ${setTypography('small')}
  position: absolute;
  bottom: -2.5em;
`;

export const Section = styled.div`
  padding: ${layout.contentPadding.h}rem 300rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  &:not(:last-child) {
    border-bottom: 1px solid ${rgba(colors.black, layout.lineOpacity)};
  }
`;

export const FieldGroup = styled.div`
  &:not(:last-child) {
    margin-bottom: 120rem;
  }

  > button {
    height: 60rem;
  }  
`;

export const FieldGroupLabel = styled.div`
  ${setTypography('small')}
  opacity: 0.5;
  margin-bottom: 60rem;
`;

export const Fields = styled.div`
  &:not(:last-child) {
    margin-bottom: 60rem;
  }
`;

export const Field = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 20rem;
  }

  > div:first-child {
    width: 180rem;
  }
`;

export const FieldLabel = styled.div<{ isBoolean?: boolean }>`
  ${({ isBoolean }) => !isBoolean ? `
    opacity: 0.5;
  ` : ''}
`;

export const FieldValue = styled.div``;