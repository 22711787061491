import React, { useEffect, useMemo, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { sortBy } from 'lodash';

import { Option } from 'context/tool.types';
import { ReactComponent as SvgChevron } from 'svgs/chevron.svg';

import * as FormStyled from 'components/forms/forms.styles';
import * as Styled from './dropdownMenu.styles';

export interface DropdownMenuProps {
  label?: string;
  options: Option[];
  value: string | number;
  onChange(value: DropdownMenuProps['value']): void;
  instruction?: string;
  error?: string;
  defaultIndex?: number;
  isReadOnly?: boolean;
  isDisabled?: boolean;
}

const DropdownMenu = ({
  label = '',
  options = [],
  onChange,
  value,
  instruction = '',
  error = '',
  defaultIndex = null,
  isReadOnly = false,
  isDisabled = false
}: DropdownMenuProps) => {
  const sortedOptions = useMemo(() => sortBy(options, (option) => option.label.toLowerCase()), [options]);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(
    defaultIndex ?? sortedOptions.findIndex((option) => option.value === value)
  );

  const handleOnChange = (index: number) => {
    if (!isReadOnly) setCurrentIndex(index);
    setOpen(false);
  };

  useEffect(() => {
    const newValue = sortedOptions[currentIndex].value;
    onChange(newValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  const onToggle = () => {
    setOpen(!isOpen);
  };

  return (
    <Styled.Wrapper isDisabled={isDisabled}>
      {label && <FormStyled.Label>{label}{instruction && '*'}</FormStyled.Label>}
      <Styled.DropdownMenu onClick={onToggle} hasError={!!error} isOpen={isOpen}>
        {sortedOptions[currentIndex].label}
        <SvgChevron />
      </Styled.DropdownMenu>
      <AnimatePresence>
        {isOpen ? (
          <Styled.DropdownMenuOptions
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0, transition: { duration: 0.2 } }}
            exit={{ opacity: 0, y: -10, transition: { duration: 0.2 } }}
          >
            {sortedOptions.map((option, index) => (
              <Styled.DropdownMenuOption
                key={option.value}
                isSelected={index === currentIndex}
                isReadOnly={isReadOnly}
                onClick={() => handleOnChange(index)}
              >
                {option.label}
              </Styled.DropdownMenuOption>
            ))}
          </Styled.DropdownMenuOptions>
        ) : null}
        </AnimatePresence>

      {instruction && <FormStyled.Instruction>{instruction}</FormStyled.Instruction>}
      {error && <FormStyled.Error>{error}</FormStyled.Error>}
    </Styled.Wrapper>
  );
};

export default DropdownMenu;
