import React from 'react';

import { ReactComponent as SvgChevron } from 'svgs/chevron.svg';
import { colors } from 'utils/styles/theme';

import * as Styled from './button.styles';

export interface ButtonProps {
  label: string;
  onClick(): void;
  color?: keyof typeof colors;
  isBig?: boolean;
  isThin?: boolean;
  isDisabled?: boolean;
  arrow?: 'left' | 'right';
  count?: number;
}

const Button = ({
  label,
  onClick,
  color = 'blue',
  isBig = false,
  isThin = false,
  isDisabled = false,
  arrow = null,
  count = null
}: ButtonProps) => (
  <Styled.Wrapper
    onClick={onClick}
    color={color}
    isBig={isBig}
    isThin={isThin}
    disabled={isDisabled}
    arrow={arrow}
    hasCount={count !== null}
  >
    <Styled.Background color={color} />
    {arrow && (
      <Styled.Arrow direction={arrow} isBig={isBig} isThin={isThin}>
        <SvgChevron />
      </Styled.Arrow>
    )}
    {count !== null && (
      <Styled.Count color={color}>
        {count}
      </Styled.Count>
    )}
    <div>{label}</div>
  </Styled.Wrapper>
);

export default Button;
