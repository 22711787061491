import styled from 'styled-components';
import { motion } from 'framer-motion';

import { modal, setVh, setTypography } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';

export const Modal = styled(motion.div)`
  ${modal()}
  max-height: ${setVh(70)};
  overflow-y: auto;
`;

export const ModalTitle = styled.div`
  ${setTypography('heading3')}
  margin-bottom: ${1.5 * layout.contentPadding.h}rem;
`;

export const ModalMessage = styled.div<{ isError: boolean }>`
  ${setTypography('small')}
  margin-bottom: 60rem;
  color: ${({ isError }) => isError ? colors.atomicTangerine : colors.algaeGreen};
`;

export const ModalClose = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-50%, 50%);
`;

export const ModalSection = styled.div<{ isScrollable?: boolean }>`
  width: 100%;
  text-align: left;

  ${({ isScrollable }) => isScrollable ? `
    overflow: hidden;
    display: flex;
    flex-direction: column;
  ` : ''}

  &:not(:last-child) {
    margin-bottom: 80rem;
  }

  &:last-of-type {
    margin-bottom: 100rem;
  }
`;

export const ModalSectionLabel = styled.div`
  ${setTypography('small')}
  opacity: 0.5;
  margin-bottom: 10rem;
`;

export const ModalInput = styled.div<{ isButtonRotated?: boolean; hasError?: boolean }>`
  position: relative;

  > button {
    top: 50%;
    position: absolute;
    right: 0;
    margin-top: ${({ hasError }) => hasError ? -0.75 : 0.25}em;

    ${({ isButtonRotated }) => isButtonRotated ? `
      transform: translateY(-50%) rotate(90deg);
    ` : `
      transform: translateY(-50%);
    `}
  }
`;
