import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { pdfjs } from 'react-pdf';
import { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/react';

import NonFunctionals from 'components/nonFunctionals/nonFunctionals';
import StandaloneVersion from 'components/versioning/standaloneVersion/standaloneVersion';
import Version from 'components/versioning/version/version';

import { InfoProvider } from 'context/info';
import { ModalProvider } from 'context/modal';
import { ToolProvider } from 'context/tool';
import { UserProvider } from 'context/user';

import HomePage from 'routes/home/home.loadable';
import UserProfilePage from 'routes/userProfile/userProfile';

import { ROUTES } from 'utils/routes';
import theme from 'utils/styles/theme';

if (process.env.ENV !== 'local') {
  Sentry.init({
    enabled: process.env.NODE_ENV !== 'development',
    dsn: process.env.SENTRY_DSN,
  });
}

pdfjs.GlobalWorkerOptions.workerSrc = process.env.PDF_WORKER_URL.replace('${PDFJS_VERSION}', pdfjs.version);

// TODO: (FE) Implement motion design
// TODO: (FE) Implement 9Sky for text copy handling
// TODO: (FE) Implement page titles, favicon, metatags, tracking

const App = () => (
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <UserProvider>
        <ToolProvider>
          <InfoProvider>
            <ModalProvider>
              <NonFunctionals>
                <Switch>
                  <Route exact path={[ROUTES.HOME, ROUTES.RESET_PASSWORD]}>
                    <HomePage />
                  </Route>
                  <Route exact path={ROUTES.USER_PROFILE}>
                    <UserProfilePage />
                  </Route>
                </Switch>
              </NonFunctionals>
            </ModalProvider>
          </InfoProvider>
        </ToolProvider>
      </UserProvider>
    </BrowserRouter>

    {process.env.IS_DEBUG && <Version />}
    {process.env.IS_DEBUG && window.location.hash === '#version' && <StandaloneVersion />}
  </ThemeProvider>
);

export default App;
