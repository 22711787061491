import React, {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';

export interface Info {
  title: string;
  description: ReactNode | ReactNode[];
}

interface InfoProviderProps {
  children: ReactNode | ReactNode[];
}

export interface InfoContextType {
  info: Info;
  isWarning: boolean;
  setInfo(info: Info, isWarning?: boolean): void;
}

const defaultContext: InfoContextType = {
  info: null,
  isWarning: false,
  setInfo: () => null
};

export const InfoContext: React.Context<InfoContextType> =
  createContext<InfoContextType>(defaultContext);

export const InfoProvider = ({ children }: InfoProviderProps): JSX.Element => {
  const [info, setInfo] = useState<InfoContextType['info']>(defaultContext.info);
  const [isWarning, setWarning] = useState<InfoContextType['isWarning']>(defaultContext.isWarning);

  const updateInfo = useCallback((newInfo: Info, isWarning = false) => {
    setInfo(newInfo);
    setWarning(isWarning);
  }, []);

  const contextValue: InfoContextType = useMemo(() => ({
    info,
    isWarning,
    setInfo: updateInfo
  }), [info, isWarning, updateInfo]);

  return (
    <InfoContext.Provider value={contextValue}>
      {children}
    </InfoContext.Provider>
  );
};
