import { rgba } from 'polished';
import styled from 'styled-components';

import { setVh } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';

export const Wrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${setVh(100)};
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div<{ isColumn?: boolean }>`
  position: relative;
  display: flex;
  flex: 1;
  height: ${setVh(100 - layout.header.h)};

  ${({ isColumn }) => isColumn ? 'flex-direction: column;' : ''}
`;

export const Page = styled.div<{ hasBottomBorder?: boolean; hasFooter?: boolean }>`
  position: relative;
  flex: 1;
  display: flex;
  height: ${({ hasFooter }) => hasFooter ? `calc(100% - ${layout.footer.h}rem)` : '100%'};

  ${({ hasBottomBorder }) => hasBottomBorder ? `
    border-bottom: 1px solid ${rgba(colors.black, layout.lineOpacity)};
  ` : ''}
`;

export const Footer = styled.div`
  width: 100%;
  height: ${layout.footer.h}rem;
  align-items: center;
  display: flex;
  padding: 0 ${layout.pagePadding.w}rem;
`;
