import styled from 'styled-components';

import { hover, isDarkColor, setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

import { ButtonProps } from './button';

export const Wrapper = styled.button<{
  color: ButtonProps['color'];
  isBig: ButtonProps['isBig'];
  isThin: ButtonProps['isThin'];
  arrow: ButtonProps['arrow'];
  hasCount: boolean
}>`
  position: relative;
  color: ${({ color }) => isDarkColor(color) ? colors.white : 'inherit'};
  z-index: 0;

  ${setTypography('button')};
  padding: ${({ isBig }) => isBig ? 30 : 20}rem 30rem;
  border-radius: 10rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s ease-out, color 0.2s ease-out;

  ${({ isBig }) => isBig ? `
    width: 100%;
  ` : `
    min-width: 160rem;
  `}

  ${({ isThin }) => isThin ? `
    padding: 10rem 30rem;
    margin-top: -4rem;
  ` : ''}

  ${({ arrow, isBig }) => arrow === 'right' && !isBig ? `
    flex-direction: row-reverse;
  ` : ''}

  ${({ hasCount }) => hasCount ? `
    height: 60rem;
    padding-left: 80rem;
    width: 200rem;
  ` : ''}

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

export const Background = styled.div<{ color: ButtonProps['color'] }>`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 10rem;
  background: ${({ color }) => colors[color]};
  z-index: -1;
  transition: transform 0.2s ease-out, background 0.2s ease-out;

  ${hover(`
    transform: scale(1.01);
  `)}
`;

export const Arrow = styled.div<{
  direction: ButtonProps['arrow'];
  isBig: ButtonProps['isBig'];
  isThin: ButtonProps['isThin'];
}>`
  height: 1em;
  margin-top: -4rem;

  ${({ direction, isBig }) => `
    ${isBig ? direction : `margin-${direction === 'left' ? 'right' : 'left'}`}: ${30 / (isBig ? 1 : 2)}rem;
  `}

  ${({ isBig }) => isBig ? `
    position: absolute;
  ` : ''}

  ${({ direction }) => direction === 'right' ? `
    transform: rotate(180deg);
  ` : ''}

  svg {
    width: auto;
    height: 100%;
  }
`;

export const Count = styled.div<{ color: ButtonProps['color'] }>`
  height: calc(100% - 20rem);
  width: 40rem;
  background: ${({ color }) => color === 'black' ? colors.white : colors.black};
  color: ${({ color }) => color === 'black' ? colors.black : colors.white};
  border-radius: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;
  margin-right: 40rem;
  position: absolute;
  left: 10rem;
  transition: background 0.2s ease-out, color 0.2s ease-out;
  ${setTypography('small')}
`;
