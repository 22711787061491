export const defaultFontSize = 24; // px

export const breakpointTablet = 712; // px
export const breakpointDesktop = 1025; // px
export const breakpointDesktopLarge = 1280; // px

export const desktopMinHeight = 600; // px
export const breakpointMobileSmallHeight = 600; // px

export const mobileBaseWidth = 1125; // px
export const desktopBaseWidth = 4320; // px

export const mobileMinFontSize = 11; // px
export const desktopMinFontSize = 12; // px

export const mobileScalableFontSize = 100 / mobileBaseWidth; // vw
export const mobileSmallHeightScalableFontSize = 0.0875; // vw
export const tabletScalableFontSize = 0.05; // vw
export const desktopScalableFontSize = 100 / desktopBaseWidth; // vw
export const desktopWideScalableFontSize = 0.027; // vh

export const desktopWideAspectRatio = '20 / 11';
