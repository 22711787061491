import { css } from 'styled-components';
import { kebabCase } from 'lodash';
import { rgba } from 'polished';

import { pulse } from './animations';
import { mediaDesktop, mediaTablet } from './responsive';
import { colors, fonts, layout } from './theme';

// Fix Safari mobile fix, where vh values are wrongly calculated
// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
export const setVh = (value: number) => `calc(var(--vh, 1vh) * ${value})`;

export const hover = (content: string, mustHoldOnTablet = false) => `
  &:active {
    ${content}
  }

  ${(mustHoldOnTablet ? mediaDesktop : mediaTablet)(`
    &:hover {
      ${content}
    }
  `)}
`;

export const setVisibility = (isVisible: boolean) => `
  transition: opacity 0.2s ease-out;

  ${!isVisible ? `
    opacity: 0;
    pointer-events: none;
  ` : ''}
`;

export const setTypography = (category: keyof typeof fonts.scale) => `
  ${Object.entries(fonts.scale[category])
    .map(([key, value]) => {
      const isRemUnit = ['fontSize', 'letterSpacing'].includes(key);
      const isObject = ['fontSize', 'lineHeight'].includes(key);
      const parsedKey = kebabCase(key);
      return `${parsedKey}: ${isObject ? (value as any).mobile : value}${isRemUnit && value ? 'rem' : ''};`;
    })
    .join('\n')}

  ${mediaTablet(`
    ${Object.entries(fonts.scale[category])
      .filter(([key]) => ['fontSize', 'lineHeight'].includes(key))
      .map(([key, value]) => {
        const isRemUnit = ['fontSize'].includes(key);
        const parsedKey = kebabCase(key);
        return `${parsedKey}: ${(value as any).tablet}${isRemUnit && value ? 'rem' : ''};`;
      })
      .join('\n')}
  `)}
`;

export const panel = (hasBackgroundChange = true) => `
  ${hasBackgroundChange ? `background: ${colors.white};` : ''}
  border-radius: ${layout.borderRadius}rem;
  box-shadow: 0 0 30rem 5rem ${rgba(colors.black, 0.15)};
  overflow: hidden;
`;

export const activePanel = (isActive: boolean, hasTransform = true) => `
  width: ${isActive ? 100.5 : 100}%;
  border-bottom: 1px solid ${rgba(colors.black, isActive ? 0 : layout.lineOpacity)};
  transition:
    transform 0.2s ease-out,
    background 0.2s ease-out,
    border-radius 0.2s ease-out,
    border-bottom 0.2s ease-out;

  ${isActive ? `
    ${panel()}
    ${hasTransform ? 'transform: translateY(-20rem);' : ''}
    left: -0.25%;
  ` : ''}
`;

export const topColorBar = (isActive: boolean, color: keyof typeof colors) => `
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 25rem;
    top: 0;
    left: 0;
    background: ${colors[color]};
    transition: transform 0.2s ease-out;
    transform-origin: center top;

    ${!isActive ? `
      transform: scaleY(0);
    ` : ''}
  }
`;

export const content = (
  isHovered: boolean,
  color: keyof typeof colors,
  hasTopColorBar = true,
  hasBackgroundChange = true
) => `
  position: relative;
  flex: 1;
  background: linear-gradient(to bottom, ${rgba(colors.black, layout.inactiveBackgroundOpacity)}, ${rgba(colors.black, 0)});
  transition: background 0.2s ease-out;
  display: flex;
  flex-direction: column;

  ${isHovered ? `
    ${panel(hasBackgroundChange)}
    border-radius: 0;
    border-left-color: transparent;
    box-shadow: none;
    overflow: visible;
    z-index: 2;
  ` : ''}

  ${hasTopColorBar ? topColorBar(isHovered, color) : ''}
`;

export const sideBar = (isHovered: boolean, color: keyof typeof colors) => `
  position: relative;
  width: ${layout.sideBar.w}%;
  background: linear-gradient(to bottom, ${rgba(colors.black, layout.inactiveBackgroundOpacity)}, ${rgba(colors.black, 0)});
  border-left: 1px solid ${rgba(colors.black, layout.lineOpacity)};
  transition: border-left 0.2s ease-out, background 0.2s ease-out, border-radius 0.2s ease-out;
  display: flex;
  flex-direction: column;

  ${isHovered ? `
    ${panel()}
    border-left-color: transparent;
    z-index: 2;
  ` : ''}

  ${topColorBar(isHovered, color)}
`;

export const headerSection = () => `
  padding: 0 ${layout.contentPadding.h}rem;
  height: ${layout.headerSection.h}rem;
  border-bottom: 1px solid ${rgba(colors.black, layout.lineOpacity)};
  display: flex;
  align-items: center;

  button {
    margin: 0;
  }
`;

export const sidebarListItem = (isTransparent: boolean) => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.2s ease-out;
  height: 80rem;

  &:not(:last-child) {
    padding-bottom: 20rem;
    margin-bottom: 20rem;
    border-bottom: 1px solid ${rgba(colors.black, layout.lineOpacity)};
  }

  ${isTransparent ? `
    opacity: 0.5;
  ` : ''}

  > div {
    height: 80%;
    display: flex;
    align-items: center;
  }
`;

export const modal = () => `
  ${panel()}
  position: fixed;
  text-align: center;
  padding: ${1.5 * layout.contentPadding.h}rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  z-index: 12;
`;

export const input = (hasError: boolean) => `
  position: relative;
  width: 100%;
  border-bottom: 1px solid ${hasError ? colors.atomicTangerine : rgba(colors.black, 0.2)};
  padding: 15rem 0;
  ${setTypography('input')};
  transition: color 0.2s ease-out;

  ${hasError ? `
    color: ${colors.atomicTangerine};
  ` : ''}

  &::placeholder {
    opacity: 0.5;
  }
`;

export const spinner = (color: keyof typeof colors, progress = 1, isThin = false) => `
  width: 100%;
  padding-top: 100%;
  border-radius: 50%;
  background: ${`conic-gradient(${rgba(colors[color], 0.2)}, ${colors[color]} ${progress}turn, transparent 0)`};
  position: relative;
  mask-image: radial-gradient(transparent ${isThin ? 64 : 50}%, black ${isThin ? 64 : 50}%);
`;

export const modalSpinner = () => `
  width: 100rem;
  height: 100rem;
  z-index: 12;
`;

export const activeTag = (isVisible = true) => `
  ${setTypography('label')}
  border: 1px solid currentColor;
  border-radius: 60rem;
  padding: 8rem 20rem;
  cursor: default;

  ${setVisibility(isVisible)}
`;

export const isDarkColor = (color: keyof typeof colors) => ['black', 'blue'].includes(color);

export const strippedBackground = () => `
  background-image: linear-gradient(to right, ${rgba(colors.black, 0.1)}, ${rgba(colors.black, 0.1)} 55%, transparent 55%, transparent 100%);
  background-size: 40rem 1px;
  mask-image: linear-gradient(to bottom, black, transparent 75%);
`;

export const contentColumn = () => `
  flex: 1;
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    border-right: 1px solid ${rgba(colors.black, layout.lineOpacity)}; 
  }
`;

export const contentElement = (isEmpty = false) => `
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 40rem ${layout.contentPadding.h}rem;
  overflow-y: auto;
  position: relative;

  &:not(:last-child) {
    border-bottom: 1px solid ${rgba(colors.black, layout.lineOpacity)}; 
  }

  ${isEmpty ? strippedBackground() : ''}
`;

// Bezier curves
export const easeInSine = 'cubic-bezier(0.470, 0.000, 0.745, 0.715)';
export const easeOutSine = 'cubic-bezier(0.390, 0.575, 0.565, 1.000)';
export const easeInOutSine = 'cubic-bezier(0.445, 0.050, 0.550, 0.950)';
export const easeInQuad = 'cubic-bezier(0.550, 0.085, 0.680, 0.530)';
export const easeOutQuad = 'cubic-bezier(0.250, 0.460, 0.450, 0.940)';
export const easeInOutQuad = 'cubic-bezier(0.455, 0.030, 0.515, 0.955)';
export const easeInCubic = 'cubic-bezier(0.550, 0.055, 0.675, 0.190)';
export const easeOutCubic = 'cubic-bezier(0.215, 0.610, 0.355, 1.000)';
export const easeInOutCubic = 'cubic-bezier(0.645, 0.045, 0.355, 1.000)';
export const easeInQuart = 'cubic-bezier(0.895, 0.030, 0.685, 0.220)';
export const easeOutQuart = 'cubic-bezier(0.165, 0.840, 0.440, 1.000)';
export const easeInOutQuart = 'cubic-bezier(0.770, 0.000, 0.175, 1.000)';
export const easeInQuint = 'cubic-bezier(0.755, 0.050, 0.855, 0.060)';
export const easeOutQuint = 'cubic-bezier(0.230, 1.000, 0.320, 1.000)';
export const easeInOutQuint = 'cubic-bezier(0.860, 0.000, 0.070, 1.000)';
export const easeInExpo = 'cubic-bezier(0.950, 0.050, 0.795, 0.035)';
export const easeOutExpo = 'cubic-bezier(0.190, 1.000, 0.220, 1.000)';
export const easeInOutExpo = 'cubic-bezier(1.000, 0.000, 0.000, 1.000)';
export const easeInCirc = 'cubic-bezier(0.600, 0.040, 0.980, 0.335)';
export const easeOutCirc = 'cubic-bezier(0.075, 0.820, 0.165, 1.000)';
export const easeInOutCirc = 'cubic-bezier(0.785, 0.135, 0.150, 0.860)';
export const easeInBack = 'cubic-bezier(0.600, -0.280, 0.735, 0.045)';
export const easeOutBack = 'cubic-bezier(0.175, 0.885, 0.320, 1.275)';
export const easeInOutBack = 'cubic-bezier(0.680, -0.550, 0.265, 1.550)';

// Animations
export const pulseAnimation = (transform?: string, duration = 3.5) => css`
  animation: ${pulse(transform)} ${duration}s ${easeOutCubic} forwards;
`;
