import { useEffect, useRef, useState } from 'react';

import ResizeService from 'services/resize.service';

const windowSize = () => ResizeService.size;

const useWindowSize = () => {
  const [size, setSize] = useState(windowSize());
  const [isMounted, setMounted] = useState<boolean>(false);
  const isRendered = useRef<boolean>(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      ResizeService.addListeners();
      isRendered.current = true;
    }
  }, [isMounted]);

  useEffect(() => {
    const onResize = () => {
      if (isRendered.current) setSize(windowSize());
    };

    ResizeService.add(onResize);
    return () => {
      ResizeService.remove(onResize);
      setMounted(false);
      isRendered.current = false;
    };
  }, []);

  return size;
};

export default useWindowSize;
