import React, { useEffect } from 'react';

import * as Styled from './windowTooSmall.styles';

const WindowTooSmall = () => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <Styled.Wrapper>
      Please enlarge your browser window
    </Styled.Wrapper>
  );
};

export default WindowTooSmall;
