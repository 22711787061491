import { SectionConfig } from './userInfo.types';

export const sectionConfig: SectionConfig = [
  [
    {
      label: 'Account Information',
      modal: 'userAccount',
      fields: [
        {
          type: 'string',
          config: {
            label: 'Email',
            fieldName: 'email'
          }
        },
        {
          type: 'string',
          config: {
            label: 'Council',
            fieldName: 'council',
            isReadOnly: true
          }
        }
      ]
    },
    {
      label: 'Security',
      modal: 'userSecurity',
      fields: [
        {
          type: 'password',
          config: {
            label: 'Password'
          }
        }
      ]
    }
  ],
  [
    {
      label: 'Notification Settings',
      fields: [
        {
          type: 'boolean',
          config: {
            label: 'Enable email contact from other platform users',
            fieldName: 'notification_setting'
          }
        }
      ]
    }
  ]
];
