import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';

import { TOOL_STEPS } from 'utils/routes';

import { ToolStep, Site, ToolData, Scheme, Scenario } from './tool.types';
import { UserContextType, UserContext } from './user';

interface ToolProviderProps {
  children: ReactNode | ReactNode[];
}

export interface ToolContextType {
  step: ToolStep;
  currentSite: Site;
  currentScheme: Scheme;
  currentScenario: Scenario;
  data: ToolData;
  sites: Site[];
  isComputingScenario: boolean;
  setStep(stepId: ToolStep['id']): void;
  setData(data: ToolData): void;
  setSites(sites: Site[]): void;
  setCurrentSite(patch: { [k in keyof Site]?: Site[k] }): void;
  setCurrentScheme(patch: { [k in keyof Scheme]?: Scheme[k] }): void;
  setCurrentScenario(patch: { [k in keyof Scenario]?: Scenario[k] }): void;
  setComputingScenario(state: ToolContextType['isComputingScenario']): void;
  getUserSites(): Site[];
}

const defaultData: ToolData = {
  analysis: null,
  tempID: null,
  contexts: null,
  accreditations: null,
  designAdaptabilities: null,
  mmcCategories: null,
  materialQualities: null,
  maxNoUnits: null,
  renewableTechnologies: null,
  dwellingTypes: null,
  nDSS: null,
  totalKpis: 0,
  matchingKpis: 0,
  energyEfficiencies: null,
  energyPerformances: null,
  parametersConfig: null
};

const defaultContext: ToolContextType = {
  step: null,
  currentSite: null,
  currentScheme: null,
  currentScenario: null,
  data: defaultData,
  sites: [],
  isComputingScenario: false,
  setStep: () => null,
  setData: () => null,
  setSites: () => null,
  setCurrentSite: () => null,
  setCurrentScheme: () => null,
  setCurrentScenario: () => null,
  setComputingScenario: () => null,
  getUserSites: () => null,
};

export const ToolContext: React.Context<ToolContextType> =
  createContext<ToolContextType>(defaultContext);

export const ToolProvider = ({ children }: ToolProviderProps): JSX.Element => {
  const { user: currentUser }: UserContextType = useContext(UserContext);
  const [step, setStep] = useState<ToolContextType['step']>(defaultContext.step);
  const [currentSite, setCurrentSite] = useState<ToolContextType['currentSite']>(defaultContext.currentSite);
  const [currentScheme, setCurrentScheme] = useState<ToolContextType['currentScheme']>(defaultContext.currentScheme);
  const [currentScenario, setCurrentScenario] = useState<ToolContextType['currentScenario']>(defaultContext.currentScenario);
  const [data, setData] = useState<ToolContextType['data']>(defaultContext.data);
  const [sites, setSites] = useState<ToolContextType['sites']>(defaultContext.sites);
  const [isComputingScenario, setComputingScenario] = useState<ToolContextType['isComputingScenario']>(defaultContext.isComputingScenario);
  const [previousSiteId, setPreviousSiteId] = useState<number>(defaultContext.currentSite?.id);
  const updateStep = useCallback((stepId: string) => {
    const toolSteps = [...TOOL_STEPS.lgo, ...TOOL_STEPS.mmc];
    const newStep = toolSteps.find((toolStep) => toolStep.id === stepId);
    setStep(newStep || null);
  }, []);

  const getUserSites = useCallback(() => {
    if (!currentUser) return [];
    return sites.filter((site) => site.users.find((user) => user.id === currentUser.id));
  }, [sites, currentUser]);

  const patchCurrentSite: ToolContextType['setCurrentSite'] = useCallback((patch = {}) => {
    if (patch === null) setCurrentScenario(null);
    else setCurrentSite({ ...(currentSite || {}), ...patch } as Site);
  }, [currentSite]);

  const patchCurrentScheme: ToolContextType['setCurrentScheme'] = useCallback((patch = {}) => {
    if (patch === null) setCurrentScheme(null);
    else setCurrentScheme({ ...(currentScheme || {}), ...patch } as Scheme);
  }, [currentScheme]);

  const patchCurrentScenario: ToolContextType['setCurrentScenario'] = useCallback((patch = {}) => {
    if (patch === null) setCurrentScenario(null);
    else setCurrentScenario({ ...(currentScenario || {}), ...patch } as Scenario);
  }, [currentScenario]);

  useEffect(() => { // Watch for site changes to reset current scheme
    if (previousSiteId !== currentSite?.id) {
      setCurrentScheme(null);
      setPreviousSiteId(currentSite.id);
    }
  }, [currentSite]);

  const contextValue: ToolContextType = useMemo(() => ({
    step,
    currentSite,
    currentScheme,
    currentScenario,
    data,
    sites,
    isComputingScenario,
    setStep: updateStep,
    setData,
    setCurrentSite: patchCurrentSite,
    setCurrentScheme: patchCurrentScheme,
    setCurrentScenario: patchCurrentScenario,
    setSites,
    setComputingScenario,
    getUserSites
  }), [
    step,
    currentSite,
    currentScheme,
    currentScenario,
    data,
    sites,
    isComputingScenario,
    updateStep,
    patchCurrentSite,
    patchCurrentScheme,
    patchCurrentScenario,
    setComputingScenario,
    getUserSites
  ]);

  return (
    <ToolContext.Provider value={contextValue}>
      {children}
    </ToolContext.Provider>
  );
};
