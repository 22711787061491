import { User } from 'context/user.types';

export const NAMESPACE = 'mmc';

export const setStoredUser = (storedUser: User) => {
  if (!storedUser) {
    window.localStorage.removeItem(`${NAMESPACE}.user`);
    return;
  }

  window.localStorage.setItem(`${NAMESPACE}.user`, JSON.stringify(storedUser));
};

export const getStoredUser = (): User => JSON.parse(window.localStorage.getItem(`${NAMESPACE}.user`));
