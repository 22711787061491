import { useCallback, useState } from 'react';

interface HoveredStates {
  content: boolean;
  sidebar: boolean;
}

const useHoveredStates = (defaultSection: keyof HoveredStates = 'content') => {
  const [hoveredStates, setHoveredStates] = useState<HoveredStates>({
    content: defaultSection === 'content',
    sidebar: defaultSection === 'sidebar'
  });

  const updateHoveredStates = useCallback((section: keyof typeof hoveredStates, state: boolean) => {
    const newStates = Object.fromEntries(
      Object.entries(hoveredStates).map(([key]) => [key, key !== section ? false : state])
    ) as unknown as HoveredStates;

    setHoveredStates(newStates);
  }, [hoveredStates]);

  return {
    hoveredStates,
    updateHoveredStates
  };
};

export default useHoveredStates;
