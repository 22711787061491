import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { AnimatePresence, MotionProps } from 'framer-motion';

import * as Styled from './modal.styles';

interface ModalProviderProps {
  children: ReactNode | ReactNode[];
}

export const modalAnimConfig: MotionProps = {
  initial: { opacity: 0, x: 10 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 10 },
  transition: { duration: 0.2, delay: 0.1 }
};

export interface ModalContextType {
  currentModalId: '' | 'toolLoading' | 'siteEditorLoading' | 'notifications' | 'siteSearch' | 'siteDelete'
    | 'scenarioDelete' | 'scenarioList' | 'userAccount' | 'userSecurity' | 'userInvite';
  content: ReactNode | ReactNode[];
  portal: HTMLDivElement;
  setModal(
    modalId: ModalContextType['currentModalId'],
    content?: ModalContextType['content'],
    portal?: ModalContextType['portal'],
    onClose?: () => void,
    dismiss?: () => void
  ): void;
  isClosing: boolean;
}

const defaultContext: ModalContextType = {
  currentModalId: '',
  content: null,
  portal: null,
  setModal: () => null,
  isClosing: false
};

export const ModalContext: React.Context<ModalContextType> =
  createContext<ModalContextType>(defaultContext);

// TODO: (FE) Adjust to allow overlaying/switching between modals if one is already open
// Example: the `siteSearch` modal is active, then the user gets an info card in `notifications`
export const ModalProvider = ({ children }: ModalProviderProps): JSX.Element => {
  const [currentModalId, setCurrentModalId] = useState<ModalContextType['currentModalId']>(defaultContext.currentModalId);
  const [content, setContent] = useState<ModalContextType['content']>(defaultContext.content);
  const [portal, setPortal] = useState<ModalContextType['portal']>(defaultContext.portal);
  const [isClosing, setClosing] = useState<boolean>(false);
  const onClose = useRef<() => void>(null);
  const dismiss = useRef<() => void>(null);
  
  const updateModal = useCallback(
    (
      modalId: ModalContextType['currentModalId'],
      modalContent: ModalContextType['content'] = null,
      modalPortal: ModalContextType['portal'] = null,
      onModalClose: () => void = null,
      modalDismiss: () => void = null
    ) => {
      if (modalId) {
        setContent(modalContent);
        setPortal(modalPortal);
        onClose.current = onModalClose;
        dismiss.current = modalDismiss;
      }

      setCurrentModalId(modalId);
    },
    []
  );

  const resetModal = () => {
    setContent(null);
  };

  const resetPortal = () => {
    if (portal) setPortal(null);
  };

  useEffect(() => {
    if (!currentModalId && portal) setClosing(true);
  }, [currentModalId, portal]);

  useEffect(() => {
    if (isClosing && !portal) {
      if (onClose.current) {
        onClose.current();
        onClose.current = null;
      }

      setClosing(false);
    }
  }, [isClosing, portal]);

  const contextValue: ModalContextType = useMemo(() => ({
    currentModalId,
    content,
    portal,
    setModal: updateModal,
    isClosing,
  }), [currentModalId, content, portal, updateModal, isClosing]);

  const modalDOM = (
    <AnimatePresence onExitComplete={resetPortal}>
      {content ? (
        <Styled.Wrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <Styled.Overlay onClick={() => dismiss?.current?.()}/>
          <AnimatePresence onExitComplete={resetModal}>
            {currentModalId ? content : null}
          </AnimatePresence>
        </Styled.Wrapper>
      ) : null}
    </AnimatePresence>
  );

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      {portal ? (
        ReactDOM.createPortal(
          modalDOM,
          portal
        )
      ) : modalDOM}
    </ModalContext.Provider>
  );
};
