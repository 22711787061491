import React, {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import Button from 'components/button/button';
import DropdownMenu from 'components/dropdownMenu/dropdownMenu';
import IconButton from 'components/iconButton/iconButton';
import Input from 'components/input/input';
import Toggle from 'components/toggle/toggle';
import { modalAnimConfig } from 'context/modal';
import { UserContextType, UserContext } from 'context/user';
import { Council, UserDetails } from 'context/user.types';
import useModal from 'hooks/useModal';
import { getCouncils, parseFormErrors, updateUser } from 'utils/api';
import { PAGE_COLORS } from 'utils/routes';

import { sectionConfig } from './userInfo.config';
import { Field, FieldConfig, FieldGroup } from './userInfo.types';

import * as UserProfileStyled from './../userProfile.styles';
import * as Styled from './userInfo.styles';

interface UserInfoProps {
  isHovered?: boolean;
  onHoverChange(state: boolean): void;
}

interface UserInfoErrors {
  fields: {
    [f in keyof UserDetails]: string;
  };
  message: string;
}

const defaultErrors: UserInfoErrors = {
  fields: {
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    current_password: '',
    notification_setting: '',
    council_id: ''
  },
  message: ''
};

const UserInfo = ({ isHovered, onHoverChange }: UserInfoProps) => {
  const { user }: UserContextType = useContext(UserContext);
  const [isEditingName, setEditingName] = useState<boolean>(false);
  const [isAccountEditOpen, setAccountEditOpen] = useState<boolean>(false);
  const [isSecurityEditOpen, setSecurityEditOpen] = useState<boolean>(false);
  const [editedUser, setEditedUser] = useState<UserDetails>({
    name: user.name,
    email: user.email,
    notification_setting: user.notification_setting,
    council_id: user.council_id
  });
  const [errors, setErrors] = useState<UserInfoErrors>(defaultErrors);
  const [councils, setCouncils] = useState<Council[]>([]);

  const councilConfig = useMemo(() => sectionConfig
    .flat()
    .map((fieldGroup) => fieldGroup.fields)
    .flat()
    .find((field) => (field.config as FieldConfig['string']).fieldName === 'council').config as FieldConfig['string']
  , []);

  const saveData = useCallback(async (editedFields: (keyof UserDetails)[], previousData?: UserDetails) => {
    let hasErrors = false;

    try {
      const patch = Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(editedUser).filter(([field, value]: [keyof UserDetails, any]) => editedFields.includes(field))
      );
      await updateUser(patch);

      setErrors(defaultErrors);
    } catch (error) {
      const parsedErrors = parseFormErrors(error) as UserInfoErrors;
      setErrors(parsedErrors);
      hasErrors = true;

      // Revert on fail
      if (previousData) setEditedUser(previousData);
    }

    return hasErrors;
  }, [editedUser]);

  const onNameEditClick = () => {
    if (!editedUser.name && isEditingName) return;
    const newState = !isEditingName;
    setEditingName(newState);

    if (!newState) saveData(['name']);
  };

  const onEditedUserChange = useCallback((
    field: keyof UserDetails,
    value: UserDetails[keyof UserDetails],
    isAutoSave = false
  ) => {
    const previousData = { ...editedUser };
    setEditedUser({ ...previousData, [field]: value });
    setErrors({
      ...errors,
      fields: { ...errors.fields, [field]: '' }
    });

    if (isAutoSave) saveData([field], previousData);
  }, [editedUser, errors, saveData]);

  const renderField = (field: Field) => {
    if (field.type === 'string') {
      const fieldConfig = field.config as FieldConfig['string'];
      const value = user[fieldConfig.fieldName];

      return (
        <>
          <Styled.FieldLabel>{fieldConfig.label}:</Styled.FieldLabel>
          <Styled.FieldValue>{value}</Styled.FieldValue>
        </>
      );
    } else if (field.type === 'password') {
      const fieldConfig = field.config as FieldConfig['password'];
      return (
        <>
          <Styled.FieldLabel>{fieldConfig.label}:</Styled.FieldLabel>
          <Styled.FieldValue>***********</Styled.FieldValue>
        </>
      );
    } else if (field.type === 'boolean') {
      const fieldConfig = field.config as FieldConfig['boolean'];
      return (
        <>
          <Styled.FieldValue>
            <Toggle
              value={editedUser[fieldConfig.fieldName] as boolean}
              onChange={(value) => onEditedUserChange(fieldConfig.fieldName, value, true)}
            />
          </Styled.FieldValue>
          <Styled.FieldLabel isBoolean>{fieldConfig.label}</Styled.FieldLabel>
        </>
      );
    }

    return null;
  };

  const onModalOpen = (modalName: FieldGroup['modal']) => {
    if (modalName === 'userAccount') setAccountEditOpen(true);
    else if (modalName === 'userSecurity') setSecurityEditOpen(true);
  };

  const closeModalAndSave = useCallback(async (modalName: FieldGroup['modal']) => {
    const hasErrors = await saveData(modalName === 'userAccount'
      ? ['email'] // The council is read-only for now
      : ['password', 'password_confirmation', 'current_password']
    );
    if (hasErrors) return;

    if (modalName === 'userAccount') setAccountEditOpen(false);
    else if (modalName === 'userSecurity') setSecurityEditOpen(false);
  }, [saveData]);

  useEffect(() => {
    const fetchCouncils = async () => {
      const response = await getCouncils();

      // TODO: (BE) Update once endpoint created
      // const councilList: Council[] = response.data;
      let councilList: Council[] = response.data;
      // TODO: (BE) Remove once endpoint created
      councilList = councilList.map((council) => ({
        ...council,
        // Ensure the matching council shows in the dropdown menu with the user's council's name
        name: council.id === user.council_id ? user.council : council.name
      }));

      setCouncils(councilList);
    };

    fetchCouncils();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userAccountModalDOM = useMemo(() => (
    <UserProfileStyled.Modal {...modalAnimConfig}>
      <UserProfileStyled.ModalClose>
        <IconButton icon="close" color="black" onClick={() => setAccountEditOpen(false)} />
      </UserProfileStyled.ModalClose>

      <UserProfileStyled.ModalTitle>Account Information</UserProfileStyled.ModalTitle>

      <UserProfileStyled.ModalSection>
        <UserProfileStyled.ModalInput isButtonRotated>
          <Input
            type="email"
            label="Email"
            value={editedUser.email}
            onChange={(value) => onEditedUserChange('email', value)}
            onSubmit={() => closeModalAndSave('userAccount')}
            error={errors.fields.email}
          />
          <IconButton
            icon="arrow"
            color={PAGE_COLORS.userProfile}
            onClick={() => closeModalAndSave('userAccount')}
          />
        </UserProfileStyled.ModalInput>
      </UserProfileStyled.ModalSection>

      <UserProfileStyled.ModalSection>
        <UserProfileStyled.ModalSectionLabel>Council</UserProfileStyled.ModalSectionLabel>
        <DropdownMenu
          value={editedUser.council_id}
          onChange={(value) => onEditedUserChange('council_id', value)}
          error={errors.fields.council_id}
          options={councils.map((council) => ({ value: council.id, label: council.name }))}
          // The user isn't currently allowed to change their council
          isReadOnly={councilConfig.isReadOnly}
          isDisabled={councilConfig.isReadOnly}
        />
      </UserProfileStyled.ModalSection>

      <Button label="Save" onClick={() => closeModalAndSave('userAccount')} isThin />
    </UserProfileStyled.Modal>
  ), [
    closeModalAndSave,
    councilConfig.isReadOnly,
    councils,
    editedUser,
    errors.fields.council_id,
    errors.fields.email,
    onEditedUserChange
  ]);

  const userSecurityModalDOM = useMemo(() => (
    <UserProfileStyled.Modal {...modalAnimConfig}>
      <UserProfileStyled.ModalClose>
        <IconButton icon="close" color="black" onClick={() => setSecurityEditOpen(false)} />
      </UserProfileStyled.ModalClose>

      <UserProfileStyled.ModalTitle>Change Password</UserProfileStyled.ModalTitle>

      <UserProfileStyled.ModalSection>
        <UserProfileStyled.ModalInput>
          <Input
            type="password"
            label="Current Password"
            value={editedUser.current_password}
            onChange={(value) => onEditedUserChange('current_password', value)}
            onSubmit={() => closeModalAndSave('userSecurity')}
            error={errors.fields.current_password}
          />
        </UserProfileStyled.ModalInput>
      </UserProfileStyled.ModalSection>

      <UserProfileStyled.ModalSection>
        <UserProfileStyled.ModalInput>
          <Input
            type="password"
            label="New Password"
            value={editedUser.password}
            onChange={(value) => onEditedUserChange('password', value)}
            onSubmit={() => closeModalAndSave('userSecurity')}
            instruction="* Minimum 6 characters, at least one letter and one number."
            error={errors.fields.password}
          />
        </UserProfileStyled.ModalInput>
      </UserProfileStyled.ModalSection>

      <UserProfileStyled.ModalSection>
        <UserProfileStyled.ModalInput>
          <Input
            type="password"
            label="Repeat New Password"
            value={editedUser.password_confirmation}
            onChange={(value) => onEditedUserChange('password_confirmation', value)}
            onSubmit={() => closeModalAndSave('userSecurity')}
            error={errors.fields.password_confirmation}
          />
        </UserProfileStyled.ModalInput>
      </UserProfileStyled.ModalSection>

      <Button label="Save" onClick={() => closeModalAndSave('userSecurity')} isThin />
    </UserProfileStyled.Modal>
  ), [
    closeModalAndSave,
    editedUser.current_password,
    editedUser.password,
    editedUser.password_confirmation,
    errors.fields.current_password,
    errors.fields.password,
    errors.fields.password_confirmation,
    onEditedUserChange
  ]);

  const { disposeModal: disposeUserAccountModal } = useModal('userAccount', userAccountModalDOM, isAccountEditOpen, null, null, () => { setAccountEditOpen(false); });
  const { disposeModal: disposeUserSecurityModal } = useModal('userSecurity', userSecurityModalDOM, isSecurityEditOpen, null, null, () => { setSecurityEditOpen(false); });

  useEffect(() => {
    return () => {
      // Dispose of modals
      disposeUserAccountModal();
      disposeUserSecurityModal();
    };
  }, [disposeUserAccountModal, disposeUserSecurityModal]);

  return (
    <Styled.Wrapper
      onMouseMove={() => !isHovered && onHoverChange(true)}
      onMouseLeave={() => onHoverChange(false)}
      isHovered={isHovered}
      color={PAGE_COLORS.userProfile}
    >
      <Styled.Header>
        <Styled.HeaderButton>
          <IconButton icon="edit" color={isEditingName ? 'blue' : PAGE_COLORS.userProfile} onClick={onNameEditClick} />
        </Styled.HeaderButton>
        <Styled.HeaderName
          value={editedUser.name}
          readOnly={!isEditingName}
          placeholder={errors.fields.name || 'User Name'}
          hasError={!!errors.fields.name}
          onChange={({ nativeEvent: event }: SyntheticEvent) => onEditedUserChange('name', (event.target as HTMLInputElement).value)}
        />
        {errors.fields.name && <Styled.HeaderError>{errors.fields.name}</Styled.HeaderError>}
      </Styled.Header>
      {sectionConfig.map((section, index) => (
        <Styled.Section key={`s${index}`}>
          {section.map((fieldGroup, fieldGroupIndex) => (
            <Styled.FieldGroup key={`s${index}_fg${fieldGroupIndex}`}>
              <Styled.FieldGroupLabel>{fieldGroup.label}</Styled.FieldGroupLabel>
              <Styled.Fields>
                {fieldGroup.fields.map((field, fieldIndex) => (
                  <Styled.Field key={`s${index}_fg${fieldGroupIndex}_f${fieldIndex}`}>
                    {renderField(field)}
                  </Styled.Field>
                ))}
              </Styled.Fields>
              {fieldGroup.modal && (
                <Button label="Edit" color={PAGE_COLORS.userProfile} onClick={() => onModalOpen(fieldGroup.modal)} />
              )}
            </Styled.FieldGroup>
          ))}
        </Styled.Section>
      ))}
    </Styled.Wrapper>
  );
};

export default UserInfo;
